import { Box, Cylinder, Float, Text } from '@react-three/drei';
import { GroupProps, useFrame, useLoader } from '@react-three/fiber';
import { Suspense, useContext, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { CanvasTexture, FrontSide, Group, Mesh, MeshStandardMaterial, ShadowMaterial, sRGBEncoding, TextureLoader, Vector3, Vector3Tuple } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { degToRad, radToDeg } from 'three/src/math/MathUtils';
import { ControlsContext } from '../scenes/BaseScene';
import { Font } from '../utils/Constant';

const rotateAngle = new Vector3(0, 1, 0);

export function RoomPrefab(props: GroupProps) {
  const gltf = useLoader(GLTFLoader, '/models/museum.glb')

  const shadowRoot = useRef<Group>(null!);

  useEffect(() => {
    shadowRoot.current.clear();

    gltf.scene.traverse(async object => {
      const mesh = object as Mesh;
      if (mesh.isMesh) {
        mesh.receiveShadow = true;
        const material = mesh.material as MeshStandardMaterial;
        material.side = FrontSide;
        mesh.renderOrder = 11;

        const shadow = mesh.clone();
        shadow.material = new ShadowMaterial();
        shadow.material.opacity = 0.4;

        if (!!shadowRoot) {
          shadowRoot.current.add(shadow)
        }
      }
    })
  }, [gltf])

  return (
    <group {...props}>
      <group ref={shadowRoot}></group>
      <Suspense fallback={null}>
        <primitive object={gltf.scene} />
      </Suspense>
    </group>
  )
}

export function ProjectBoard(props: GroupProps) {
  const [id] = useState('ProjectBoard');
  const [active, setActive] = useState(false);
  const [isZoomedIn, setZoomedIn] = useState(false);

  const [currentContext, setCurrentContext] = useState('main');

  const { focus, currentFocusedId, cancelPressed } = useContext(ControlsContext);

  function init() {
    const pos = props.position as Vector3Tuple
    const rot = (props.rotation ?? [0, 0, 0]) as Vector3Tuple
    const target = new Vector3(pos[0], pos[1], pos[2]).add(new Vector3(isMobile ? 0.18 : 0, 0, 0))
    focus(true, id, target, radToDeg(rot[1]), 90, isMobile ? 5 : 2);
    setCurrentContext('main')
  }

  function activate() {
    if (active) return;

    setActive(true)
  }

  function deactivate() {
    if (!active) return;

    setZoomedIn(false);
    setActive(false);
    focus(false);
  }

  function zoomToLocalPosition(localPosition: Vector3Tuple, distance: number, context?: string) {
    if (!active) return;

    const pos = props.position as Vector3Tuple
    const rot = (props.rotation ?? [0, 0, 0]) as Vector3Tuple
    const target = new Vector3(pos[0], pos[1], pos[2]).add(new Vector3(localPosition[0], localPosition[1], localPosition[2]).applyAxisAngle(rotateAngle, rot[1]))
    focus(true, id, target, radToDeg(rot[1]), 85, isMobile ? distance * 1.8 : distance);
    setZoomedIn(true)

    if (!!context) {
      setCurrentContext(context)
    }
  }

  useEffect(() => {
    if (currentFocusedId === id) {
      activate();
    } else {
      deactivate();
    }
  }, [currentFocusedId])

  useEffect(() => {
    if (!active) return;

    if (cancelPressed) {
      if (isZoomedIn) {
        init();
        setZoomedIn(false);
      } else {
        deactivate();
      }
    }
  }, [cancelPressed])

  const pic = useLoader(TextureLoader, '/pictures/about.png')

  return (
    <group {...props}>
      {!active &&
        <HoverPrompt position={[0, 0.02, 0.12]} offset={[0, 0., 0.1]} fontSize={0.09} width={0.5} dimensions={[2.18, 1.55, 0.24]} onClick={init}>
          This Project
        </HoverPrompt>
      }
      <mesh position={[0, 0.0278, 0.22]}>
        <boxGeometry args={[1.5387, 1.0214, 0.02]}></boxGeometry>
        <meshStandardMaterial map={pic}></meshStandardMaterial>
      </mesh>
      {active && currentContext === 'main' &&
        <>
          <HoverPrompt position={[1.24, -0.63, 0.09]} offset={[0, 0, 0.12]} fontSize={0.024} width={0.15} dimensions={[0.28, 0.19, 0.24]} onClick={() => { zoomToLocalPosition([1.24, -0.63, 0.1], 0.45, "label") }}>
            More Info
          </HoverPrompt>
          <Button position={[0, -0.55, 0.22]} width={0.12} fontSize={0.04} onClick={deactivate} theme="light">Back</Button>
        </>
      }
      <group position={[1.137, -0.58, 0.208]}>
        <Text anchorX="left" anchorY="bottom-baseline" color="#000000" fontSize={0.013} font={Font.LibreBold}>
          Jukrapop Kongkaew (b. 1997)
        </Text>
        <Text position={[0, -0.025, 0]} anchorX="left" anchorY="bottom-baseline" color="#000000" fontSize={0.013} font={Font.LibreItalic}>
          Virutal Museum Tour, 2022
        </Text>
        <Text position={[0, -0.05, 0]} anchorX="left" anchorY="bottom-baseline" color="#000000" fontSize={0.013} font={Font.LatoLight}>
          Personal gallery created using
        </Text>
        <Text position={[0, -0.065, 0]} anchorX="left" anchorY="bottom-baseline" color="#000000" fontSize={0.013} font={Font.LatoLight}>
          Three.js, Three React Fiber, Blender
        </Text>
        {active && currentContext === 'label' &&
          <>
            <Button position={[0.1, -0.1, 0]} width={0.1} fontSize={0.01} onClick={() => {
              openLinkInNewTab("https://skfb.ly/oznSB")
            }}>View on Sketchfab</Button>
            <Button position={[0.01, 0.05, 0]} width={0.04} fontSize={0.01} onClick={() => {
              init();
              setZoomedIn(false);
            }}>Back</Button>
          </>
        }
      </group>
    </group >
  )
}

export function Instagram(props: GroupProps) {
  const [id] = useState('Instagram');
  const [active, setActive] = useState(false);
  const [isZoomedIn, setZoomedIn] = useState(false);

  const [currentContext, setCurrentContext] = useState('main');

  const { focus, currentFocusedId, cancelPressed } = useContext(ControlsContext);

  function init() {
    const pos = props.position as Vector3Tuple
    const rot = (props.rotation ?? [0, 0, 0]) as Vector3Tuple
    const target = new Vector3(pos[0], pos[1], pos[2]).add(new Vector3(0, -0.075, 0))
    focus(true, id, target, radToDeg(rot[1]), 90, 1.6);
    setCurrentContext('main')
  }

  function activate() {
    if (active) return;

    setActive(true)
  }

  function deactivate() {
    if (!active) return;

    setZoomedIn(false);
    setActive(false);
    focus(false);
  }

  function zoomToLocalPosition(localPosition: Vector3Tuple, distance: number, context?: string) {
    if (!active) return;

    const pos = props.position as Vector3Tuple
    const rot = (props.rotation ?? [0, 0, 0]) as Vector3Tuple
    const target = new Vector3(pos[0], pos[1], pos[2]).add(new Vector3(localPosition[0], localPosition[1], localPosition[2]).applyAxisAngle(rotateAngle, rot[1]))
    focus(true, id, target, radToDeg(rot[1]), 85, isMobile ? distance * 1.8 : distance);
    setZoomedIn(true)

    if (!!context) {
      setCurrentContext(context)
    }
  }

  useEffect(() => {
    if (currentFocusedId === id) {
      activate();
    } else {
      deactivate();
    }
  }, [currentFocusedId])

  useEffect(() => {
    if (!active) return;

    if (cancelPressed) {
      if (isZoomedIn) {
        init();
        setZoomedIn(false);
      } else {
        deactivate();
      }
    }
  }, [cancelPressed])

  function openLink() {
    openLinkInNewTab('https://www.instagram.com/glasspile/')
  };

  const pic = useLoader(TextureLoader, '/pictures/gp1.png')
  const qrCode = useLoader(TextureLoader, '/pictures/qr-code.png')

  return (
    <group {...props}>
      {!active &&
        <HoverPrompt position={[0, 0.02, 0.19]} offset={[0, 0, 0.03]} fontSize={0.05} width={0.22} dimensions={[0.6, 0.95, 0.05]} onClick={init}>
          Instagram
        </HoverPrompt>
      }
      <mesh position={[0, 0.0278, 0.209]}>
        <boxGeometry args={[0.4557, 0.705, 0.015]}></boxGeometry>
        <meshStandardMaterial map={pic}></meshStandardMaterial>
      </mesh>
      {active && currentContext === 'main' &&
        <>
          <HoverPrompt position={[0.202, -0.575, 0.09]} offset={[0, 0, 0.12]} fontSize={0.024} width={0.15} dimensions={[0.28, 0.19, 0.24]} onClick={() => { zoomToLocalPosition([0.202, -0.575, 0.09], 0.45, "label") }}>
            More Info
          </HoverPrompt>
          <Button position={[0, -0.36, 0.21]} width={0.09} fontSize={0.026} onClick={deactivate} theme="light">Back</Button>
        </>
      }
      <group position={[0.103, -0.526, 0.208]}>
        <Text anchorX="left" anchorY="bottom-baseline" color="#000000" fontSize={0.013} font={Font.LibreBold}>
          Jukrapop Kongkaew (b. 1997)
        </Text>
        <Text position={[0, -0.025, 0]} anchorX="left" anchorY="bottom-baseline" color="#000000" fontSize={0.013} font={Font.LibreItalic}>
          Glasspile, 2020
        </Text>
        <Text position={[0, -0.044, 0]} anchorX="left" anchorY="bottom-baseline" color="#000000" fontSize={0.0115} font={Font.LatoRegular}>
          Side project / hobby
        </Text>
        <Text position={[0, -0.060, 0]} anchorX="left" anchorY="top-baseline" color="#000000" fontSize={0.009} font={Font.LatoLight} textAlign="justify" maxWidth={0.12}>
          A collection of my 3D and 2D arts. An idea outlet. It keeps me busy on weekends, a perfect way to keep practicing and getting better at 3D modeling.
        </Text>
        <mesh position={[0.17, -0.08, 0]}>
          <planeGeometry args={[0.08, 0.08]}></planeGeometry>
          <meshStandardMaterial alphaMap={qrCode} map={qrCode} transparent color="#000000"></meshStandardMaterial>
        </mesh>
        {active && currentContext === 'label' &&
          <>
            <Button position={[0.01, 0.05, 0]} width={0.04} fontSize={0.01} onClick={() => {
              init();
              setZoomedIn(false);
            }}>Back</Button>
            <HoverPrompt position={[0.17, -0.08, 0]} offset={[0, 0, 0]} fontSize={0.008} width={0.04} dimensions={[0.08, 0.08, 0.01]} onClick={openLink}>
              Open
            </HoverPrompt>
          </>
        }
      </group>
    </group>
  )
}

export function AiGenerated(props: GroupProps) {
  const [id] = useState('AiGenerated');
  const [active, setActive] = useState(false);
  const [isZoomedIn, setZoomedIn] = useState(false);

  const [currentContext, setCurrentContext] = useState('main');

  const { focus, currentFocusedId, cancelPressed } = useContext(ControlsContext);

  function init() {
    const pos = props.position as Vector3Tuple
    const rot = (props.rotation ?? [0, 0, 0]) as Vector3Tuple
    const target = new Vector3(pos[0], pos[1], pos[2]).add(new Vector3(0, -0.075, 0));
    focus(true, id, target, radToDeg(rot[1]), 90, 1.6);
    setCurrentContext('main')
  }

  function activate() {
    if (active) return;

    setActive(true)
  }

  function deactivate() {
    if (!active) return;

    setZoomedIn(false);
    setActive(false);
    focus(false);
  }

  function zoomToLocalPosition(localPosition: Vector3Tuple, distance: number, context?: string) {
    if (!active) return;

    const pos = props.position as Vector3Tuple
    const rot = (props.rotation ?? [0, 0, 0]) as Vector3Tuple
    const target = new Vector3(pos[0], pos[1], pos[2]).add(new Vector3(localPosition[0], localPosition[1], localPosition[2]).applyAxisAngle(rotateAngle, rot[1]))
    focus(true, id, target, radToDeg(rot[1]), 85, isMobile ? distance * 1.8 : distance);
    setZoomedIn(true)

    if (!!context) {
      setCurrentContext(context)
    }
  }

  useEffect(() => {
    if (currentFocusedId === id) {
      activate();
    } else {
      deactivate();
    }
  }, [currentFocusedId])

  useEffect(() => {
    if (!active) return;

    if (cancelPressed) {
      if (isZoomedIn) {
        init();
        setZoomedIn(false);
      } else {
        deactivate();
      }
    }
  }, [cancelPressed])

  const [index, setIndex] = useState<number>(0);
  const pictures = useLoader(TextureLoader, ['/pictures/sd5.png', '/pictures/sd2.png', '/pictures/sd3.png', '/pictures/sd4.png', '/pictures/sd1.png'])

  function goPreviousImage() {
    setIndex(index => {
      if (index === 0) {
        return pictures.length - 1;
      }
      return index - 1;
    })
  }

  function goNextImage() {
    setIndex(index => {
      if (index === pictures.length - 1) {
        return 0
      }
      return index + 1;
    })
  }

  return (
    <group {...props}>
      {!active &&
        <HoverPrompt position={[0, 0.02, 0.18]} offset={[0, 0, 0.03]} fontSize={0.05} width={0.3} dimensions={[0.6, 0.95, 0.05]} onClick={init}>
          AI Generated
        </HoverPrompt>
      }
      <mesh position={[0, 0.0278, 0.202]}>
        <boxGeometry args={[0.4557, 0.705, 0.015]}></boxGeometry>
        <meshStandardMaterial map={pictures[index]}></meshStandardMaterial>
      </mesh>
      {active && currentContext === 'main' &&
        <>
          <HoverPrompt position={[0.202, -0.575, 0.09]} offset={[0, 0, 0.12]} fontSize={0.024} width={0.15} dimensions={[0.28, 0.19, 0.24]} onClick={() => { zoomToLocalPosition([0.202, -0.575, 0.09], 0.45, "label") }}>
            More Info
          </HoverPrompt>
          <Button position={[-0.275, 0, 0.21]} width={0} fontSize={0.026} onClick={goPreviousImage} theme="light">{`<`}</Button>
          <Button position={[0.275, 0, 0.21]} width={0} fontSize={0.026} onClick={goNextImage} theme="light">{`>`}</Button>
          <Button position={[0, -0.36, 0.21]} width={0.09} fontSize={0.026} onClick={deactivate} theme="light">Back</Button>
        </>
      }
      <group position={[0.103, -0.526, 0.208]}>
        <Text anchorX="left" anchorY="bottom-baseline" color="#000000" fontSize={0.013} font={Font.LibreBold}>
          Stable Diffusion AI (b. 2022)
        </Text>
        <Text position={[0, -0.025, 0]} anchorX="left" anchorY="bottom-baseline" color="#000000" fontSize={0.013} font={Font.LibreItalic}>
          Generated Images, 2022
        </Text>
        <Text position={[0, -0.045, 0]} anchorX="left" anchorY="top-baseline" color="#000000" fontSize={0.01} font={Font.LatoLight} textAlign="justify" maxWidth={0.2}>
          {`One of my latest interests. I spent couple of days generating and training models. \nHere are some of my favourites. Enjoy :)`}
        </Text>
        {active && currentContext === 'label' &&
          <>
            <Button position={[0.01, 0.05, 0]} width={0.04} fontSize={0.01} onClick={() => {
              init();
              setZoomedIn(false);
            }}>Back</Button>
          </>
        }
      </group>
    </group>
  )
}

export function DrawingBoard(props: GroupProps) {
  const [id] = useState('DrawingBoard');
  const [active, setActive] = useState(false);
  const [isZoomedIn, setZoomedIn] = useState(false);

  const [currentContext, setCurrentContext] = useState('main');

  const { focus, currentFocusedId, cancelPressed } = useContext(ControlsContext);

  function init() {
    const pos = props.position as Vector3Tuple
    const rot = (props.rotation ?? [0, 0, 0]) as Vector3Tuple
    const target = new Vector3(pos[0], pos[1], pos[2]).add(new Vector3(0, -0.075, 0))
    focus(true, id, target, radToDeg(rot[1]), 90, 1.6);
    setCurrentContext('main')
  }

  function activate() {
    if (active) return;

    setActive(true)
  }

  function deactivate() {
    if (!active) return;

    setZoomedIn(false);
    setActive(false);
    focus(false);
  }

  function zoomToLocalPosition(localPosition: Vector3Tuple, distance: number, context?: string) {
    if (!active) return;

    const pos = props.position as Vector3Tuple
    const rot = (props.rotation ?? [0, 0, 0]) as Vector3Tuple
    const target = new Vector3(pos[0], pos[1], pos[2]).add(new Vector3(localPosition[0], localPosition[1], localPosition[2]).applyAxisAngle(rotateAngle, rot[1]))
    focus(true, id, target, radToDeg(rot[1]), 85, isMobile ? distance * 1.8 : distance);
    setZoomedIn(true)

    if (!!context) {
      setCurrentContext(context)
    }
  }

  useEffect(() => {
    if (currentFocusedId === id) {
      activate();
    } else {
      deactivate();
    }
  }, [currentFocusedId])

  useEffect(() => {
    if (!active) return;

    if (cancelPressed) {
      if (isZoomedIn) {
        init();
        setZoomedIn(false);
      } else {
        deactivate();
      }
    }
  }, [cancelPressed])

  const [contactPosition, setContactPosition] = useState<Vector3>(new Vector3());

  function Pencil(props: GroupProps) {
    const mesh = useLoader(GLTFLoader, '/models/pencil.glb');
    return (
      <group {...props}>
        <group position={[0, -0.17, 0]}>
          <primitive object={mesh.scene} />
        </group>
      </group>
    )
  }

  const canvasBoard = useRef<Mesh>(null!);
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false);
  const [canvasElement] = useState<Element>(document.querySelector('.konvajs-content')?.children[0]!);

  function getCanvasPosition(point: Vector3) {
    const dimensions = isMobile ? { x: 200, y: 300 } : { x: 600, y: 900 }
    const worldDimensions = { x: 0.4557, y: 0.705 }

    const coords = canvasBoard.current.worldToLocal(point);

    const position = {
      x: (coords.x + (worldDimensions.x / 2)) / worldDimensions.x * dimensions.x,
      y: (coords.y - (worldDimensions.y / 2)) / -worldDimensions.y * dimensions.y,
    }
    return position;
  }

  function mouseDown(point: Vector3) {
    setIsMouseDown(true);
    const coords = getCanvasPosition(point);

    if (!isMobile) {
      canvasElement.dispatchEvent(new PointerEvent('mousedown', {
        bubbles: true,
        clientX: coords.x,
        clientY: coords.y,
      }));
    }
  }

  function mouseMove(point: Vector3) {
    if (!isMouseDown) return;

    const coords = getCanvasPosition(point);

    if (coords.y > (isMobile ? 300 : 900)) return;

    canvasElement.dispatchEvent(new PointerEvent('mousemove', {
      bubbles: true,
      clientX: coords.x,
      clientY: coords.y,
    }));
  }

  function mouseUp(point: Vector3) {
    setIsMouseDown(false);

    canvasElement.dispatchEvent(new PointerEvent('mouseup', {
      bubbles: true,
    }));
  }

  function mouseEnter(point: Vector3) {
    if (isMouseDown) {
      const coords = getCanvasPosition(point);

      canvasElement.dispatchEvent(new PointerEvent('mousedown', {
        bubbles: true,
        clientX: coords.x,
        clientY: coords.y,
      }));
    }
  }

  function mouseLeave() {
    if (isMouseDown) {
      window.addEventListener('pointerup', () => {
        setIsMouseDown(false)
      }, { once: true })

      canvasElement.dispatchEvent(new PointerEvent('mouseup', {
        bubbles: true,
      }));
    }
  }

  function clearCanvas() {
    window.dispatchEvent(new Event('cv_clearCanvas'));
  }

  function download() {
    window.dispatchEvent(new Event('cv_download'));
  }

  const [texture, setTexture] = useState<CanvasTexture>();

  function initCanvas() {
    if (!canvasBoard.current) return;

    const texture = new CanvasTexture(canvasElement as HTMLCanvasElement);
    setTexture(texture);
    texture.encoding = sRGBEncoding;
    const material = new MeshStandardMaterial({ map: texture, transparent: true });
    canvasBoard.current.material = material;
  }

  function updateCanvas() {
    if (!!texture) {
      texture.needsUpdate = true;
    }
  }

  useEffect(() => {
    initCanvas();
  }, [])

  useFrame(() => {
    if (active) {
      updateCanvas();
    }
  })

  return (
    <group {...props}>
      {!active &&
        <HoverPrompt position={[0, 0.02, 0.18]} offset={[0, 0, 0.03]} fontSize={0.05} width={0.10} dimensions={[0.6, 0.95, 0.05]} onClick={init}>
          Draw
        </HoverPrompt>
      }
      <Pencil position={active && contactPosition.y !== -Infinity && currentContext === 'main' ? contactPosition : [0.275, 0.1, 0.216]} rotation={active && contactPosition.y !== -Infinity && currentContext === 'main' ? [degToRad(-23), 0, degToRad(28)] : [0, 0, 0]}></Pencil>
      <mesh ref={canvasBoard} position={[0, 0.0278, 0.209]}>
        <boxGeometry args={[0.4557, 0.705, 0.015]}></boxGeometry>
      </mesh>
      {active && currentContext === 'main' &&
        <>
          <mesh position={[0, 0.0278, 0.209]} visible={false}
            onPointerDown={(event) => {
              mouseDown(event.point)
            }}
            onPointerUp={(event) => {
              mouseUp(event.point)
            }}
            onPointerMove={(event) => {
              mouseMove(event.point)
            }}
            onPointerEnter={(event) => {
              mouseEnter(event.point)
            }}
            onPointerLeave={(event) => {
              mouseLeave()
            }}
          >
            <planeGeometry args={[0.4557, 0.705]}></planeGeometry>
          </mesh>
          <mesh position={[0, 0.0278, 0.209]} visible={false} onPointerMove={(event) => { setContactPosition(event.object.parent!.worldToLocal(event.point)) }} onPointerOut={() => { setContactPosition(new Vector3(0, -Infinity, 0)) }}>
            <planeGeometry args={[0.65, 0.94]}></planeGeometry>
            <meshStandardMaterial></meshStandardMaterial>
          </mesh>
          <HoverPrompt position={[0.174, -0.575, 0.09]} offset={[0, 0, 0.12]} fontSize={0.024} width={0.15} dimensions={[0.28, 0.19, 0.24]} onClick={() => { zoomToLocalPosition([0.174, -0.575, 0.09], 0.45, "label") }}>
            More Info
          </HoverPrompt>
          <Button position={[0, -0.36, 0.21]} width={0.09} fontSize={0.026} onClick={deactivate} theme="light">Back</Button>
          <Button position={[-0.16, 0.42, 0.21]} width={0.09} fontSize={0.026} onClick={clearCanvas} theme="light">Clear</Button>
          <Button position={[0.13, 0.42, 0.21]} width={0.15} fontSize={0.026} onClick={download} theme="light">Download</Button>
        </>
      }
      <group position={[0.075, -0.526, 0.208]}>
        <Text anchorX="left" anchorY="bottom-baseline" color="#000000" fontSize={0.013} font={Font.LibreBold}>
          You
        </Text>
        <Text position={[0, -0.025, 0]} anchorX="left" anchorY="bottom-baseline" color="#000000" fontSize={0.013} font={Font.LibreItalic}>
          Sketch on The Wall, Now
        </Text>
        <Text position={[0, -0.05, 0]} anchorX="left" anchorY="bottom-baseline" color="#000000" fontSize={0.013} font={Font.LatoRegular}>
          Let's put your drawing on display
        </Text>
        <Text position={[0, -0.072, 0]} anchorX="left" anchorY="top-baseline" textAlign="justify" color="#000000" fontSize={0.01} font={Font.LatoLight} maxWidth={0.195}>
          Show me your fantastic drawing skills and download to keep it forever!
        </Text>
        {active && currentContext === 'label' &&
          <>
            <Button position={[0.01, 0.05, 0]} width={0.04} fontSize={0.01} onClick={() => {
              init();
              setZoomedIn(false);
            }}>Back</Button>
          </>
        }
      </group>
    </group>
  )
}

export function Tree(props: GroupProps) {
  const [id] = useState('Tree');
  const [active, setActive] = useState(false);
  const [isZoomedIn, setZoomedIn] = useState(false);

  const { focus, currentFocusedId, cancelPressed } = useContext(ControlsContext);

  function init() {
    const pos = (props.position as Vector3Tuple)
    pos[0] += -1.5
    const rot = (props.rotation ?? [0, 0, 0]) as Vector3Tuple
    const target = new Vector3(pos[0], pos[1], pos[2]).add(new Vector3(isMobile ? 0.2 : 0, 0, 0))
    focus(true, id, target, radToDeg(rot[1]) + -200, 100, isMobile ? 5 : 4);
  }

  function activate() {
    if (active) return;

    setActive(true)
  }

  function deactivate() {
    if (!active) return;

    setZoomedIn(false);
    setActive(false);
    focus(false);
  }

  useEffect(() => {
    if (currentFocusedId === id) {
      activate();
    } else {
      deactivate();
    }
  }, [currentFocusedId])

  useEffect(() => {
    if (!active) return;

    if (cancelPressed) {
      if (isZoomedIn) {
        init();
        setZoomedIn(false);
      } else {
        deactivate();
      }
    }
  }, [cancelPressed])

  function openLink() {
    openLinkInNewTab('https://skfb.ly/oupyu')
  };

  return (
    <group {...props}>
      {!active &&
        <HoverPrompt lookAtCamera position={[0, 0.02, 0]} offset={[0, -0.5, 0]} cameraOffset={[0, 0, 0.25]} fontSize={0.09} width={0.5} dimensions={[0.5, 3, 0.5]} onClick={init}>
          Tree
        </HoverPrompt>
      }
      {active &&
        <group position={[-1.1, 0.3, 0]} rotation={[0, degToRad(180), 0]}>
          <Button position={[0.06, 0.38, 0]} width={0.2} fontSize={0.07} onClick={deactivate} theme="light">Back</Button>
          <Text position={[0, 0, 0]} anchorX="left" anchorY="bottom-baseline" color="#000000" fontSize={0.13} font={Font.LibreItalic}>
            This Random Tree
          </Text>
          <HoverPrompt position={[0.7, -0.16, 0]} offset={[0, 0, 0]} fontSize={0.08} width={1.42} dimensions={[1.4, 0.15, 0.05]} onClick={openLink}>
            View On Sketchfab
          </HoverPrompt>
          <Text position={[0, -0.2, 0]} anchorX="left" anchorY="bottom-baseline" color="#000000" fontSize={0.1} font={Font.LatoLight}>
            Realistic Tree Vol.2 by aliyeredon
          </Text>
          <Text position={[0, -0.35, 0]} anchorX="left" anchorY="bottom-baseline" color="#000000" fontSize={0.1} font={Font.LatoLight}>
            Licensed under CC Attribution
          </Text>
          <mesh rotation={[0, 0, degToRad(90)]} position={[0.7, -0.12, -0.002]} renderOrder={10}>
            <planeGeometry args={[0.8, 1.6]}></planeGeometry>
            <meshBasicMaterial color="#ffffff" opacity={0.05} transparent></meshBasicMaterial>
          </mesh>
        </group>
      }
    </group>
  )
}

export function Skills(props: GroupProps) {
  const [id] = useState('Skills');
  const [active, setActive] = useState(false);
  const [isZoomedIn, setZoomedIn] = useState(false);

  const [currentContext, setCurrentContext] = useState('main');

  const { focus, currentFocusedId, cancelPressed } = useContext(ControlsContext);

  function init() {
    const pos = props.position as Vector3Tuple
    const rot = (props.rotation ?? [0, 0, 0]) as Vector3Tuple
    const target = new Vector3(pos[0], pos[1], pos[2]).add(new Vector3(0, 0, isMobile ? 0.12 : 0))
    focus(true, id, target, radToDeg(rot[1]), 90, isMobile ? 3.2 : 2);
    setCurrentContext('main')
  }

  function activate() {
    if (active) return;

    setActive(true)
  }

  function deactivate() {
    if (!active) return;

    setZoomedIn(false);
    setActive(false);
    focus(false);
  }

  function zoomToLocalPosition(localPosition: Vector3Tuple, distance: number, context?: string) {
    if (!active) return;

    const pos = props.position as Vector3Tuple
    const rot = (props.rotation ?? [0, 0, 0]) as Vector3Tuple
    const target = new Vector3(pos[0], pos[1], pos[2]).add(new Vector3(localPosition[0], localPosition[1], localPosition[2]).applyAxisAngle(rotateAngle, rot[1]))
    focus(true, id, target, radToDeg(rot[1]), 85, isMobile ? distance * 1.8 : distance);
    setZoomedIn(true)

    if (!!context) {
      setCurrentContext(context)
    }
  }

  useEffect(() => {
    if (currentFocusedId === id) {
      activate();
    } else {
      deactivate();
    }
  }, [currentFocusedId])

  useEffect(() => {
    if (!active) return;

    if (cancelPressed) {
      if (isZoomedIn) {
        init();
        setZoomedIn(false);
      } else {
        deactivate();
      }
    }
  }, [cancelPressed])

  const pic = useLoader(TextureLoader, '/pictures/skills.png')

  return (
    <group {...props}>
      {!active &&
        <HoverPrompt position={[0, 0.02, 0.19]} offset={[0, 0, 0.03]} fontSize={0.06} width={0.14} dimensions={[0.97, 1.43, 0.05]} onClick={init}>
          Skills
        </HoverPrompt>
      }
      <mesh position={[0, 0.0278, 0.209]}>
        <boxGeometry args={[0.63 * 1.2, 0.945 * 1.2, 0.015]}></boxGeometry>
        <meshStandardMaterial map={pic}></meshStandardMaterial>
      </mesh>
      {active && currentContext === 'main' &&
        <>
          <HoverPrompt position={[0.72, -0.665, 0.09]} offset={[0, 0, 0.12]} fontSize={0.024} width={0.15} dimensions={[0.25, 0.18, 0.24]} onClick={() => { zoomToLocalPosition([0.72, -0.665, 0.09], 0.45, "label") }}>
            More Info
          </HoverPrompt>
          <Button position={[0, -0.6, 0.21]} width={0.09} fontSize={0.026} onClick={deactivate} theme="light">Back</Button>
        </>
      }
      <group position={[0.615, -0.61, 0.208]}>
        <Text anchorX="left" anchorY="bottom-baseline" color="#000000" fontSize={0.013} font={Font.LibreBold}>
          Jukrapop Kongkaew (b. 1997)
        </Text>
        <Text position={[0, -0.025, 0]} anchorX="left" anchorY="bottom-baseline" color="#000000" fontSize={0.013} font={Font.LibreItalic}>
          Area of Skills
        </Text>
        <Text position={[0, -0.045, 0]} anchorX="left" anchorY="top-baseline" color="#000000" fontSize={0.009} font={Font.LatoLight} textAlign="justify" maxWidth={0.205}>
          Since I was a computer science student and became a full-time developer, I have discovered and honed various technical skills.
        </Text>
        <Text position={[0, -0.085, 0]} anchorX="left" anchorY="top-baseline" color="#000000" fontSize={0.009} font={Font.LatoLight} textAlign="justify" maxWidth={0.205}>
          My skill set heavily contains elements on the game development side because I've always been interested in game development since I was kid. I take every opportunity at it to improve and expose my self to the technologies as much as possible.
        </Text>
        {active && currentContext === 'label' &&
          <Button position={[0.01, 0.05, 0]} width={0.04} fontSize={0.01} onClick={() => {
            init();
            setZoomedIn(false);
          }}>Back</Button>
        }
      </group>
    </group>
  )
}

export function Projects(props: GroupProps) {
  const [id] = useState('Projects');
  const [active, setActive] = useState(false);
  const [isZoomedIn, setZoomedIn] = useState(false);

  const [currentContext, setCurrentContext] = useState('main');

  const { focus, currentFocusedId, cancelPressed } = useContext(ControlsContext);

  function init() {
    const pos = props.position as Vector3Tuple
    const rot = (props.rotation ?? [0, 0, 0]) as Vector3Tuple
    const target = new Vector3(pos[0], pos[1], pos[2]).add(new Vector3(0, 0, isMobile ? 0.12 : 0))
    focus(true, id, target, radToDeg(rot[1]), 90, isMobile ? 2.3 : 1.5);
    setCurrentContext('main')
  }

  function activate() {
    if (active) return;

    setActive(true)
  }

  function deactivate() {
    if (!active) return;

    setZoomedIn(false);
    setActive(false);
    focus(false);
  }

  function zoomToLocalPosition(localPosition: Vector3Tuple, distance: number, context?: string) {
    if (!active) return;

    const pos = props.position as Vector3Tuple
    const rot = (props.rotation ?? [0, 0, 0]) as Vector3Tuple
    const target = new Vector3(pos[0], pos[1], pos[2]).add(new Vector3(localPosition[0], localPosition[1], localPosition[2]).applyAxisAngle(rotateAngle, rot[1]))
    focus(true, id, target, radToDeg(rot[1]), 85, isMobile ? distance * 1.8 : distance);
    setZoomedIn(true)

    if (!!context) {
      setCurrentContext(context)
    }
  }

  useEffect(() => {
    if (currentFocusedId === id) {
      activate();
    } else {
      deactivate();
    }
  }, [currentFocusedId])

  useEffect(() => {
    if (!active) return;

    if (cancelPressed) {
      if (isZoomedIn) {
        init();
        setZoomedIn(false);
      } else {
        deactivate();
      }
    }
  }, [cancelPressed])

  const pic = useLoader(TextureLoader, '/pictures/works.png')

  return (
    <group {...props}>
      {!active &&
        <HoverPrompt position={[0, 0.02, 0.19]} offset={[0, 0, 0.03]} fontSize={0.05} width={0.22} dimensions={[0.67, 1, 0.05]} onClick={init}>
          Projects
        </HoverPrompt>
      }
      <mesh position={[0, 0.0278, 0.209]}>
        <boxGeometry args={[0.392779 * 1.2, 0.589169 * 1.2, 0.015]}></boxGeometry>
        <meshStandardMaterial map={pic}></meshStandardMaterial>
      </mesh>
      {active && currentContext === 'main' &&
        <>
          <HoverPrompt position={[0.51, -0.37, 0.09]} offset={[0, 0, 0.12]} fontSize={0.024} width={0.15} dimensions={[0.26, 0.19, 0.24]} onClick={() => { zoomToLocalPosition([0.51, -0.37, 0.09], 0.45, "label") }}>
            More Info
          </HoverPrompt>
          <Button position={[0, -0.38, 0.21]} width={0.09} fontSize={0.026} onClick={deactivate} theme="light">Back</Button>
          <Button position={[0.075, 0.235, 0.21]} width={0.1} fontSize={0.016} onClick={() => { openLinkInNewTab('https://www.freshconsulting.com/insights/blog/experience-the-fresh-website-in-vr/') }} theme="dark">View Article</Button>
          <Button position={[0.022, 0.07, 0.21]} width={0.1} fontSize={0.016} onClick={() => { openLinkInNewTab('https://play.google.com/store/apps/details?id=com.glasspile.dashingdino') }} theme="dark">Google Play</Button>
          <Button position={[0.14, 0.07, 0.21]} width={0.08} fontSize={0.016} onClick={() => { openLinkInNewTab('https://apps.apple.com/us/app/dashing-dino/id1498756963') }} theme="dark">App Store</Button>
          <Button position={[0.075, -0.095, 0.21]} width={0.1} fontSize={0.016} onClick={() => { openLinkInNewTab('https://www.glasspile.com/a-balloons-journey/') }} theme="dark">View Page</Button>
          <Button position={[0.075, -0.26, 0.21]} width={0.1} fontSize={0.016} onClick={() => { openLinkInNewTab('https://play.google.com/store/apps/details?id=dev.jukrapopk.notif') }} theme="dark">Google Play</Button>
        </>
      }
      <group position={[0.412, -0.32, 0.208]}>
        <Text anchorX="left" anchorY="bottom-baseline" color="#000000" fontSize={0.013} font={Font.LibreBold}>
          Jukrapop Kongkaew (b. 1997)
        </Text>
        <Text position={[0, -0.025, 0]} anchorX="left" anchorY="bottom-baseline" color="#000000" fontSize={0.013} font={Font.LibreItalic}>
          Published Projects
        </Text>
        <Text position={[0, -0.045, 0]} anchorX="left" anchorY="top-baseline" color="#000000" fontSize={0.009} font={Font.LatoLight} textAlign="justify" maxWidth={0.205}>
          Some of my works are just proof of concept, some are unfinished. These are some of the published ones. Done with various technologies and languages.
        </Text>
        {active && currentContext === 'label' &&
          <Button position={[0.01, 0.05, 0]} width={0.04} fontSize={0.01} onClick={() => {
            init();
            setZoomedIn(false);
          }}>Back</Button>
        }
      </group>
    </group>
  )
}

export function Timeline(props: GroupProps) {
  const [id] = useState('Timeline');
  const [active, setActive] = useState(false);
  const [isZoomedIn, setZoomedIn] = useState(false);

  const [currentContext, setCurrentContext] = useState('main');

  const { focus, currentFocusedId, cancelPressed } = useContext(ControlsContext);

  function init() {
    const pos = props.position as Vector3Tuple
    const rot = (props.rotation ?? [0, 0, 0]) as Vector3Tuple
    const target = new Vector3(pos[0], pos[1], pos[2]).add(new Vector3(0, 0, isMobile ? 0.15 : 0))
    focus(true, id, target, radToDeg(rot[1]), 90, isMobile ? 2.7 : 2);
    setCurrentContext('main')
  }

  function activate() {
    if (active) return;

    setActive(true)
  }

  function deactivate() {
    if (!active) return;

    setZoomedIn(false);
    setActive(false);
    focus(false);
  }

  function zoomToLocalPosition(localPosition: Vector3Tuple, distance: number, context?: string) {
    if (!active) return;

    const pos = props.position as Vector3Tuple
    const rot = (props.rotation ?? [0, 0, 0]) as Vector3Tuple
    const target = new Vector3(pos[0], pos[1], pos[2]).add(new Vector3(localPosition[0], localPosition[1], localPosition[2]).applyAxisAngle(rotateAngle, rot[1]))
    focus(true, id, target, radToDeg(rot[1]), 85, isMobile ? distance * 1.8 : distance);
    setZoomedIn(true)

    if (!!context) {
      setCurrentContext(context)
    }
  }

  useEffect(() => {
    if (currentFocusedId === id) {
      activate();
    } else {
      deactivate();
    }
  }, [currentFocusedId])

  useEffect(() => {
    if (!active) return;

    if (cancelPressed) {
      if (isZoomedIn) {
        init();
        setZoomedIn(false);
      } else {
        deactivate();
      }
    }
  }, [cancelPressed])

  const pic = useLoader(TextureLoader, '/pictures/timeline.png')

  return (
    <group {...props}>
      {!active &&
        <HoverPrompt position={[0, 0.02, 0.19]} offset={[0, 0, 0.03]} fontSize={0.05} width={0.25} dimensions={[0.97, 1.43, 0.05]} onClick={init}>
          Timeline
        </HoverPrompt>
      }
      <mesh position={[0, 0.0278, 0.209]}>
        <boxGeometry args={[0.562142 * 1.2, 0.843214 * 1.2, 0.015]}></boxGeometry>
        <meshStandardMaterial map={pic}></meshStandardMaterial>
      </mesh>
      {active && currentContext === 'main' &&
        <>
          <HoverPrompt position={[0.66, -0.578, 0.09]} offset={[0, 0, 0.12]} fontSize={0.024} width={0.15} dimensions={[0.28, 0.19, 0.24]} onClick={() => { zoomToLocalPosition([0.66, -0.578, 0.09], 0.45, "label") }}>
            More Info
          </HoverPrompt>
          <Button position={[0, -0.535, 0.21]} width={0.09} fontSize={0.026} onClick={deactivate} theme="light">Back</Button>
        </>
      }
      <group position={[0.5568, -0.526, 0.208]}>
        <Text anchorX="left" anchorY="bottom-baseline" color="#000000" fontSize={0.013} font={Font.LibreBold}>
          Jukrapop Kongkaew (b. 1997)
        </Text>
        <Text position={[0, -0.025, 0]} anchorX="left" anchorY="bottom-baseline" color="#000000" fontSize={0.013} font={Font.LibreItalic}>
          Education & Work Experience
        </Text>
        <Text position={[0, -0.045, 0]} anchorX="left" anchorY="top-baseline" color="#000000" fontSize={0.009} font={Font.LatoLight} textAlign="justify" maxWidth={0.205}>
          Gradudated from King Mongkut's University of Technology Thonburi with second-class honors.
          Comfortable and willing to work in a multinational environment.
        </Text>
        {active && currentContext === 'label' &&
          <>
            <Button position={[0.01, 0.05, 0]} width={0.04} fontSize={0.01} onClick={() => {
              init();
              setZoomedIn(false);
            }}>Back</Button>
            <Button position={[0.1, -0.1, 0]} width={0.1} fontSize={0.01} onClick={() => {
              openLinkInNewTab('https://www.linkedin.com/in/jukrapopk/')
            }}>Find me on LinkedIn</Button>
          </>
        }
      </group>
    </group>
  )
}

export function ThreeCorner(props: GroupProps) {
  return (
    <group {...props}>
      <HoverPrompt width={0.55} fontSize={0.08} dimensions={[0.5, 0.5, 0.5]} offset={[0, 0.05, 0.25]} onClick={() => { openLinkInNewTab('https://www.glasspile.com/') }}>glasspile.com</HoverPrompt>
      <Float speed={20} rotationIntensity={0.3} floatIntensity={0.4} floatingRange={[-0.06, 0.06]}>
        <Gameboy rotation={[degToRad(-24), 0, 0]}></Gameboy>
      </Float>
    </group>
  )
}

export function DestinationConfirmation(props: { destination: Vector3 }) {
  const base = useRef<Mesh>();
  const [timeoutFunction, setTimeoutFunction] = useState(setTimeout(() => { }, 10000000))

  const [shouldShrink, setShouldShrink] = useState(false);
  const shrinkSpeed = 0.03

  useEffect(() => {
    if (!base.current) return;
    base.current.position.set(props.destination.x, props.destination.y, props.destination.z);
    base.current.scale.set(1, 1, 1)
    clearTimeout(timeoutFunction);
    setTimeout(() => {
      setShouldShrink(true)
    }, 100)
    setTimeoutFunction(setTimeout(() => {
      if (!base.current) return;
      base.current.position.set(0, -Infinity, 0);
      setShouldShrink(false)
    }, 600))
  }, [base.current, props.destination])

  useFrame(() => {
    if (!base.current || !shouldShrink) return;

    if (base.current.scale.x > 0) {
      base.current.scale.set(base.current.scale.x - shrinkSpeed, base.current.scale.y - shrinkSpeed, base.current.scale.z - shrinkSpeed)
    } else {
      base.current.scale.set(0, 0, 0)
    }
  })

  return (
    <Cylinder ref={base} args={[0.3, 0.3, 0.005, 32]}>
      <meshBasicMaterial color="#ffffff" transparent opacity={0.1}></meshBasicMaterial>
    </Cylinder>
  )
}

interface HoverPromptProps extends GroupProps {
  offset?: Vector3Tuple
  width: number
  dimensions: Vector3Tuple
  fontSize?: number
  lookAtCamera?: boolean
  debug?: boolean
  cameraOffset?: Vector3Tuple
}

export function HoverPrompt(props: HoverPromptProps) {
  const prompt = useRef<Group>(null!);
  const [hovered, setHovered] = useState(false);

  useFrame((state, delta) => {
    if (props.lookAtCamera && prompt) {
      prompt.current.lookAt(state.camera.position);
    }
  });

  return (
    <group {...props} onClick={() => { }}>
      <Box args={props.dimensions} onPointerOver={(ev) => { setHovered(true); ev.stopPropagation() }} onPointerOut={() => { setHovered(false) }} onClick={(ev) => { if (!!props.onClick) { props.onClick(ev); } ev.stopPropagation() }}>
        <meshBasicMaterial color="red" wireframe visible={!!props.debug}></meshBasicMaterial>
      </Box>
      <group ref={prompt} position={props.offset}>
        {hovered &&
          <group position={props.cameraOffset ?? [0, 0, 0]}>
            <Text position={[0, 0, 0.003]} fontSize={props.fontSize ?? 0.14} maxWidth={props.width} textAlign="center" anchorX="center" anchorY="middle" color="#ffffff" font={Font.LatoRegular}>
              {props.children}
            </Text>
            <mesh rotation={[degToRad(90), 0, degToRad(90)]} position={[0, 0, 0.002]}>
              <capsuleGeometry args={[(props.fontSize ?? 0.14) * 0.9, props.width * 0.9, 6, 2]}></capsuleGeometry>
              <meshBasicMaterial color="#000000" opacity={0.67} transparent></meshBasicMaterial>
            </mesh>
          </group>
        }
      </group>
    </group >
  )
}

interface ButtonProps extends GroupProps {
  theme?: 'dark' | 'light'
  width: number
  fontSize?: number
}

export function Button(props: ButtonProps) {
  const [hovered, setHovered] = useState(false);

  return (
    <group {...props} onPointerOver={() => { }} onPointerOut={() => { }} onClick={() => { }}>
      <Text position={[0, 0, 0.003]} fontSize={props.fontSize ?? 0.14} maxWidth={props.width} textAlign="center" anchorX="center" anchorY="middle" color={hovered ? "#ababab" : "#ffffff"} font={Font.LatoRegular}>
        {props.children}
      </Text>
      {(!props.theme || props.theme === 'dark') &&
        <mesh rotation={[degToRad(90), 0, degToRad(90)]} position={[0, 0, 0.002]} renderOrder={-1} onPointerOver={(ev) => { setHovered(true); ev.stopPropagation() }} onPointerOut={() => { setHovered(false) }} onClick={(ev) => { if (!!props.onClick) { props.onClick(ev); } ev.stopPropagation(); }}>
          <capsuleGeometry args={[(props.fontSize ?? 0.14) * 0.9, props.width * 0.9, 6, 2]}></capsuleGeometry>
          <meshBasicMaterial color="#000000" opacity={hovered ? 0.3 : 0.67} transparent></meshBasicMaterial>
        </mesh>
      }
      {props.theme === 'light' &&
        <mesh rotation={[degToRad(90), 0, degToRad(90)]} position={[0, 0, 0.002]} renderOrder={-1} onPointerOver={(ev) => { setHovered(true); ev.stopPropagation() }} onPointerOut={() => { setHovered(false) }} onClick={(ev) => { if (!!props.onClick) { props.onClick(ev); } ev.stopPropagation(); }}>
          <capsuleGeometry args={[(props.fontSize ?? 0.14) * 0.9, props.width * 0.9, 6, 2]}></capsuleGeometry>
          <meshBasicMaterial color="#ffffff" opacity={hovered ? 0.1 : 0.05} transparent></meshBasicMaterial>
        </mesh>
      }
    </group >
  )
}

export function Gameboy(props: GroupProps) {
  const gltf = useLoader(GLTFLoader, '/models/gbc.glb')

  useEffect(() => {
    if (!gltf) return;

    gltf.scenes.forEach(group => {
      group.traverse(mesh => {
        mesh.castShadow = true;
        mesh.receiveShadow = true;
      })
    })
  }, [gltf])

  return (
    <group {...props}>
      <group scale={[0.1, 0.1, 0.1]}>
        <primitive object={gltf.scene} />
      </group>
    </group>
  )
}

function openLinkInNewTab(url: string) {
  window.open(url, '_blank', 'noopener,noreferrer');
};