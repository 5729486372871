import useKey from '@phntms/use-key';
import { useFrame } from '@react-three/fiber';
import { useEffect, useState } from 'react';
import { Object3D, PerspectiveCamera, Raycaster, Vector2, Vector3 } from 'three';
import { degToRad } from 'three/src/math/MathUtils';
import { Layer, negativeInfVector3, zeroVector3 } from '../utils/Constant';

export function useDirectionInput() {
  const [rawDirectionInput, setRawDirectionInput] = useState<Vector2>(new Vector2(0, 0));

  const [upPressed, setUpPressed] = useState<boolean>(false);
  const [downPressed, setDownPressed] = useState<boolean>(false);
  const [leftPressed, setLeftPressed] = useState<boolean>(false);
  const [rightPressed, setRightPressed] = useState<boolean>(false);

  useKey('d', pressed => {
    setRightPressed(pressed);
  });

  useKey('a', pressed => {
    setLeftPressed(pressed);
  });

  useKey('w', pressed => {
    setUpPressed(pressed);
  });

  useKey('s', pressed => {
    setDownPressed(pressed);
  });

  useEffect(() => {
    let input = new Vector2();
    if (downPressed && upPressed) {
      input.y = 0;
    } else if (downPressed) {
      input.y = -1;
    } else if (upPressed) {
      input.y = 1;
    }
    if (leftPressed && rightPressed) {
      input.x = 0;
    } else if (leftPressed) {
      input.x = -1;
    } else if (rightPressed) {
      input.x = 1;
    }
    setRawDirectionInput(input);
  }, [upPressed, downPressed, leftPressed, rightPressed]);

  return { rawDirectionInput }
}

export function useKeyboard() {
  const [cancelPressed, setCancelPressed] = useState<boolean>(false);

  useKey('Escape', pressed => {
    setCancelPressed(pressed);
  });

  return { cancelPressed }
}

function removeArrayFromArray(arr1: any[], arr2: any[]) {
  return arr1.filter(x => !arr2.includes(x));
}