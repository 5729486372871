import { MeshProps, useFrame } from '@react-three/fiber';
import { Dispatch, useEffect, useRef, useState } from 'react';
import { AnimationAction, AnimationMixer, Camera, Euler, Material, Mesh, Quaternion, Raycaster, Vector2, Vector3 } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { damp, degToRad } from 'three/src/math/MathUtils';
import { Layer, zeroVector3 } from '../utils/Constant';

interface Actions {
  idle: AnimationAction,
  walk: AnimationAction
}

interface CharacterControllerProps extends MeshProps {
  directionInput: Vector2;
  destination: Vector3;
  camera: Camera;
  setCharacterTarget: Dispatch<any>;
  orbitControlsChanged: number;
  hidden?: boolean,
  setCameraDistance: Dispatch<any>
}

const rotateAngle = new Vector3(0, 1, 0);
const walkingSpeed = 1.8;

export function CharacterController(props: CharacterControllerProps) {
  const character = useRef<Mesh>(null!);

  const [targetQuaternion, setTargetQuaternion] = useState<Quaternion>(null!);
  const [walkDirection, setWalkDirection] = useState<Vector3>(null!);
  const [animationMixer, setAnimationMixer] = useState<AnimationMixer>(null!);
  const [walkingWeight, setWalkingWeight] = useState<number>(0);
  const [actions, setActions] = useState<Actions>(null!);
  const [collisionRays, setCollisionRays] = useState<Raycaster[]>([new Raycaster(zeroVector3, zeroVector3, 0.01, 0.3), new Raycaster(zeroVector3, zeroVector3, 0.01, 0.3), new Raycaster(zeroVector3, zeroVector3, 0.01, 0.3)]);
  const [isAutoMoving, setIsAutoMoving] = useState<boolean>(false);
  const [isAutoRotating, setIsAutoRotating] = useState<boolean>(false);
  const [timeoutFunction, setTimeoutFunction] = useState<any>(null!);

  useEffect(() => {
    collisionRays.forEach((ray, i) => {
      ray.layers.set(Layer.Collision);
    });
  }, [collisionRays])

  //** model + animation setup  */
  useEffect(() => {
    (async () => {
      character.current.children.forEach(child => {
        child.removeFromParent();
      })
      const loader = new GLTFLoader();
      const gltf = await loader.loadAsync('/models/base.glb');
      const { scene: model } = gltf;
      const { animations } = await loader.loadAsync('/models/animations/animation-pack-m.glb');
      model.rotation.set(0, degToRad(180), 0);
      model.traverse((object) => {
        if ((object as Mesh).isMesh) {
          const mesh = object as Mesh;
          mesh.castShadow = true;
          mesh.renderOrder = 10

          const material = mesh.material as Material;
          material.transparent = true;
          material.opacity = !props.hidden ? 1 : 0.1;
        }
      });
      // let skeleton = new SkeletonHelper(model);
      // skeleton.visible = false;
      // state.scene.add(skeleton);
      const mixer = new AnimationMixer(model);
      const idleAction = mixer.clipAction(animations.find(animation => animation.name === 'Idle')!);
      const walkAction = mixer.clipAction(animations.find(animation => animation.name === 'Walk')!);
      idleAction.weight = 0;
      walkAction.weight = 1;
      idleAction.play();
      walkAction.play();

      setAnimationMixer(mixer);
      setActions({ idle: idleAction, walk: walkAction });
      character.current.add(model);
    })();
  }, []);

  useEffect(() => {
    if (character.current.children.length === 0) return;
    character.current.children[0].children[0].traverse((object) => {
      if ((object as Mesh).isMesh) {
        const mesh = object as Mesh;
        const material = mesh.material as Material;
        material.opacity = !props.hidden ? 1 : 0.1
      }
    });
  }, [props.hidden])

  useEffect(() => {
    if (!character.current || isAutoMoving || !props.camera) return;

    if (props.directionInput.length() > 0) {
      const cameraToPlayerDirection = Math.atan2(
        props.camera.position.x - character.current.position.x,
        props.camera.position.z - character.current.position.z,
      );
      const euler = new Euler(0, cameraToPlayerDirection + calculateDirectionOffset(props.directionInput), 0);
      const quaternion = new Quaternion().setFromEuler(euler);
      setTargetQuaternion(quaternion);

      const direction = new Vector3();
      props.camera.getWorldDirection(direction);
      direction.setY(0).normalize().applyAxisAngle(rotateAngle, calculateDirectionOffset(props.directionInput));
      setWalkDirection(direction);
    } else {
      setWalkDirection(zeroVector3);
    }
  }, [props.directionInput, props.orbitControlsChanged]);

  useEffect(() => {
    setIsAutoMoving(false);
    setIsAutoRotating(false);
  }, [props.directionInput]);

  useEffect(() => {
    if (!isAutoMoving) {
      setWalkDirection(zeroVector3);
    }
  }, [isAutoMoving]);

  useEffect(() => {
    const direction = new Vector3(props.destination.x - character.current.position.x, 0, props.destination.z - character.current.position.z);
    direction.normalize();
    setWalkDirection(direction);

    const distance = character.current.position.distanceTo(props.destination);

    clearTimeout(timeoutFunction);
    const timeout = setTimeout(() => {
      setIsAutoMoving(false);
    }, distance / walkingSpeed * 1000);
    setTimeoutFunction(timeout);

    const destinationToPlayerDirection = Math.atan2(
      character.current.position.x - props.destination.x,
      character.current.position.z - props.destination.z,
    )

    const euler = new Euler(0, destinationToPlayerDirection, 0);
    const quaternion = new Quaternion().setFromEuler(euler);
    setTargetQuaternion(quaternion);

    setIsAutoMoving(true);
    setIsAutoRotating(true);
  }, [props.destination]);

  useFrame((state, delta) => {
    if (!character.current || !actions) return;
    //** rotate character gradually to heading direction */
    if (props.directionInput.length() > 0 || isAutoRotating) {
      character.current.quaternion.rotateTowards(targetQuaternion, 12 * delta);
      if (character.current.quaternion.equals(targetQuaternion)) {
        setIsAutoRotating(false);
      }
    }

    //** set animations weight */
    const weight = damp(walkingWeight, walkDirection.length(), 10, delta);
    setWalkingWeight(weight);
    actions.idle.weight = 1 - weight;
    actions.walk.weight = weight;

    let shouldMove = true;

    //** raycast for walls/objects */
    const pointA = new Vector3(character.current.position.x, 0.2, character.current.position.z);
    collisionRays.forEach((ray, i) => {
      ray.ray.origin = pointA;
      const temp = walkDirection.clone().applyAxisAngle(rotateAngle, calculateDirectionOffset(new Vector2(i === 2 ? -1 : i, 1)));
      ray.ray.direction = temp.normalize();
      const intersects = collisionRays[i].intersectObjects(state.scene.children, true);
      if (intersects.length > 0) {
        shouldMove = false
      }
    });

    //** move if no obstracles */
    if (shouldMove) {
      character.current.position.x += walkDirection.x * delta * walkingSpeed;
      character.current.position.z += walkDirection.z * delta * walkingSpeed;
      if (!!props.camera) {
        props.camera.position.x += walkDirection.x * delta * walkingSpeed;
        props.camera.position.z += walkDirection.z * delta * walkingSpeed;
      }
      props.setCharacterTarget(new Vector3(character.current.position.x, 1.68, character.current.position.z));
    }
  });

  useFrame((state, delta) => {
    if (!animationMixer) return;
    animationMixer.update(delta);
  })

  const [cameraRay, setCameraRay] = useState<Raycaster>(new Raycaster(zeroVector3, zeroVector3, 0.01, 4));

  useEffect(() => {
    cameraRay.layers.set(Layer.CameraCollision);
  }, [cameraRay])

  useFrame((state, delta) => {
    if (!props.camera) return;
    const origin = new Vector3(character.current.position.x, 1.68, character.current.position.z);
    const direction = props.camera.position.clone().sub(origin).normalize()

    cameraRay.ray.origin = origin;
    cameraRay.ray.direction = direction;
    const intersects = cameraRay.intersectObjects(state.scene.children, true);
    if (intersects.length > 0) {
      props.setCameraDistance(intersects[0].distance)
    } else {
      props.setCameraDistance(cameraRay.far);
    }
  })

  return (
    <mesh ref={character} {...props}></mesh>
  )
}

function calculateDirectionOffset(rawInput: Vector2) {
  let directionOffset = 0;
  if (rawInput.y > 0) {
    if (rawInput.x < 0) {
      directionOffset = Math.PI / 4;
    } else if (rawInput.x > 0) {
      directionOffset = - Math.PI / 4;
    }
  } else if (rawInput.y < 0) {
    if (rawInput.x < 0) {
      directionOffset = Math.PI - Math.PI / 4;
    } else if (rawInput.x > 0) {
      directionOffset = Math.PI + Math.PI / 4;
    } else {
      directionOffset = Math.PI;
    }
  } else if (rawInput.x > 0) {
    directionOffset = - Math.PI / 2;
  } else if (rawInput.x < 0) {
    directionOffset = Math.PI / 2;
  }
  return directionOffset;
}