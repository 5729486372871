import { Badge, Center, CloseButton, Group, Kbd, Loader, Stack, Text } from '@mantine/core';
import { useProgress } from '@react-three/drei';
import { wait } from '@testing-library/user-event/dist/utils';
import { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { LoadingContext } from '../App';

export function LoadingScene() {
  const { progress } = useProgress()

  const [shouldTransition, setShouldTransition] = useState(false);
  const [hideHints, setHideHint] = useState(false);

  const { isStarted, setIsStarted, setIsReady } = useContext(LoadingContext);

  useEffect(() => {
    (async () => {
      if (progress >= 100) {
        setIsReady(true)
        await wait(1500);
        setShouldTransition(true)
        await wait(500);
        setIsStarted(true)
      }
    })();
  }, [progress])

  return (
    <>
      <div
        style={{
          position: "absolute",
          width: "100vw",
          zIndex: isStarted ? 1000 : -1000,
          backgroundColor: "#00000028",
          color: "#ffffff",
          pointerEvents: "auto",
          paddingTop: "6px",
          paddingBottom: "10px",
          display: isMobile || hideHints ? "none" : "inherit"
        }}>
        <Center>
          <Group>
            <Text>
              <Kbd>W</Kbd>
              <Kbd>A</Kbd>
              <Kbd>S</Kbd>
              <Kbd>D</Kbd>
            </Text>
            Move
            <Text>
              <Kbd>LMB</Kbd>
            </Text>
            Move & Interact
            <Text>
              <Kbd>RMB</Kbd>
            </Text>
            Rotate
            <CloseButton style={{ marginLeft: "80px" }} size="xl" onClick={() => { setHideHint(true) }} />
          </Group>
        </Center>
      </div>
      <div
        className={shouldTransition ? "hidden loading-scene" : "loading-scene"}
        style={{
          position: "absolute",
          width: "100vw",
          zIndex: isStarted ? -1000 : 1000,
          backgroundColor: "#ffffff",
          pointerEvents: "auto"
        }}>
        <Center style={{ width: "100%", height: "100%" }}>
          <Stack align="center">
            <Loader color="gray" size="xl" />
            <Badge variant="outline" color="gray" radius="xl">
              {progress >= 100 ?
                `Initializing`
                :
                `Loading ${progress.toFixed(0)}/100`
              }
            </Badge>
          </Stack>
        </Center>
      </div>
    </>
  )
}

