import { MantineProvider } from '@mantine/core';
import { Canvas } from '@react-three/fiber';
import { Bloom, EffectComposer, Noise, Vignette } from '@react-three/postprocessing';
import { update } from '@tweenjs/tween.js';
import { createContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import './App.css';
import { SketchCanvas } from './components/SketchCanvas';
import { BaseScene } from './scenes/BaseScene';
import { LoadingScene } from './scenes/LoadingScene';

export const LoadingContext = createContext({
  isStarted: false,
  setIsStarted: (isStarted: boolean) => { },
  isReady: false,
  setIsReady: (isReady: boolean) => { }
})

function App() {
  function animate(time: number) {
    requestAnimationFrame(animate)
    update(time)
  }
  requestAnimationFrame(animate)

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }

    if (isMobile) {
      window.addEventListener('resize', appHeight)
      appHeight()
    }
  }, [])

  const [isStarted, setIsStarted] = useState(false);
  const [isReady, setIsReady] = useState(false);

  return (
    <>
      <MantineProvider withGlobalStyles withNormalizeCSS>
        <LoadingContext.Provider value={{ isStarted, setIsStarted, isReady, setIsReady }}>
          <LoadingScene></LoadingScene>
        </LoadingContext.Provider>
        <SketchCanvas></SketchCanvas>
        <Canvas shadows>
          <EffectComposer>
            <Bloom intensity={0.8} luminanceThreshold={0.6} luminanceSmoothing={0.9} height={300} />
            <Noise opacity={0.025} />
            <Vignette eskil={false} offset={0.1} darkness={0.3} />
          </EffectComposer>
          <LoadingContext.Provider value={{ isStarted, setIsStarted, isReady, setIsReady }}>
            <BaseScene></BaseScene>
          </LoadingContext.Provider>
        </Canvas>
      </MantineProvider>
    </>
  );
}

export default App;
