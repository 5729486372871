import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import { Easing, Tween } from '@tweenjs/tween.js';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { DoubleSide, MOUSE, PerspectiveCamera as PerspectiveCameraImpl, SpotLight, Vector3 } from 'three';
import { OrbitControls as OrbitControlsImpl } from 'three-stdlib';
import { degToRad, radToDeg } from 'three/src/math/MathUtils';
import { LoadingContext } from '../App';
import { AiGenerated, DestinationConfirmation, DrawingBoard, Instagram, ProjectBoard, Projects, RoomPrefab, Skills, ThreeCorner, Timeline, Tree } from '../components/Assets';
import { CharacterController } from '../components/CharacterController';
import { useDirectionInput, useKeyboard } from '../services/InputManager';
import { Layer, zeroVector2 } from '../utils/Constant';

export const ControlsContext = createContext({
  focus: (focusing: boolean, id?: string, targetPosition?: Vector3, xzAngle?: number, yAngle?: number, distance?: number) => { },
  currentFocusedId: false as string | false,
  cancelPressed: false,
})

export function BaseScene() {
  const camera = useRef<PerspectiveCameraImpl>(null!);
  const controls = useRef<OrbitControlsImpl>(null!);
  const mainLight = useRef<SpotLight>(null!);

  useEffect(() => {
    if (!mainLight.current) return;
    mainLight.current.shadow.mapSize.set(2048, 2048);
    mainLight.current.shadow.bias = -0.001;
  }, [mainLight]);

  const { rawDirectionInput } = useDirectionInput();
  const { cancelPressed } = useKeyboard();

  const [destination, setDestination] = useState<Vector3>(new Vector3(0,0,2));
  const [orbitControlsChanged, setOrbitControlsChanged] = useState<number>(0);

  const [currentFocusedId, setCurrentFocusedId] = useState<string | false>(false)

  const [characterTarget, setCharacterTarget] = useState<Vector3>(new Vector3(0, 1.5, 0));
  const [focusTarget, setFocusTarget] = useState<Vector3>(new Vector3(0, 1.5, 0));
  const [lastCameraPosition, setLastCameraPosition] = useState<Vector3>(new Vector3(0, 0, 0));
  const [lastTweens, setLastTweens] = useState<Tween<any>[]>([])

  const [defaultCameraPreset, setDefaultCameraPreset] = useState({
    minDistance: 1.2,
    maxDistance: 20,
    minAzimuthAngle: Infinity,
    maxAzimuthAngle: Infinity,
    minPolarAngle: Math.PI / 4,
    maxPolarAngle: Math.PI / 1.6,
  })

  const [cameraPreset, setCameraPreset] = useState({
    ...defaultCameraPreset,
    maxPolarAngle: Math.PI / 2.2, minPolarAngle: Math.PI / 2.2,
    maxAzimuthAngle: -Math.PI / 5, minAzimuthAngle: -Math.PI / 5
  });

  function focus(focusing: boolean, id?: string, targetPosition?: Vector3, xzAngle?: number, yAngle?: number, distance?: number) {
    const duration = 800;

    if (id !== currentFocusedId && currentFocusedId !== false && focusing) return;
    if (id === undefined && focusing) return;

    lastTweens.forEach(tween => {
      tween.stop();
    })

    if (focusing) {
      if (lastTweens.length === 0 && currentFocusedId === false) {
        setFocusTarget(characterTarget.clone())
        setLastCameraPosition(camera.current.position.clone());
      }
      if (id !== undefined) {
        setCurrentFocusedId(id);
      }
    } else {
      setCurrentFocusedId(false)
    }

    const cameraTween = new Tween(camera.current.position)
      .easing(Easing.Quadratic.InOut)
      .to(lastCameraPosition.clone(), duration)

    const targetTween = new Tween(controls.current.target)
      .easing(Easing.Quadratic.InOut)
      .to(focusing && !!targetPosition ? targetPosition : characterTarget, duration)

    const temp = {
      minDistance: controls.current.getDistance(),
      maxDistance: controls.current.getDistance(),
      minAzimuthAngle: controls.current.getAzimuthalAngle(),
      maxAzimuthAngle: controls.current.getAzimuthalAngle(),
      minPolarAngle: controls.current.getPolarAngle(),
      maxPolarAngle: controls.current.getPolarAngle(),
    }

    const offset = focusing && xzAngle && Math.abs(xzAngle - radToDeg(controls.current.getAzimuthalAngle())) > 180 ? (xzAngle > radToDeg(controls.current.getAzimuthalAngle()) ? -360 : 360) : 0;

    const cameraPresetTween = new Tween(temp)
      .easing(Easing.Quadratic.InOut)
      .to(focusing ? {
        minDistance: distance ?? 2,
        maxDistance: distance ?? 2,
        minAzimuthAngle: Math.PI * (xzAngle !== undefined ? (offset + xzAngle) / 180 : 0),
        maxAzimuthAngle: Math.PI * (xzAngle !== undefined ? (offset + xzAngle) / 180 : 0),
        minPolarAngle: Math.PI * (yAngle !== undefined ? yAngle % 360 / 180 : 0.5),
        maxPolarAngle: Math.PI * (yAngle !== undefined ? yAngle % 360 / 180 : 0.5)
      } : defaultCameraPreset, duration)
      .onUpdate(() => {
        setCameraPreset(temp)
      })
      .onComplete(() => {
        setLastTweens([]);
      })

    setLastTweens([cameraTween, cameraPresetTween, targetTween]);

    if (!focusing) {
      cameraTween.start();
    }
    cameraPresetTween.start();
    targetTween.start();
  }

  const roomWidth = 6;
  const roomLength = 8;
  const scale = 1.2;

  const [cameraDistance, setCameraDistance] = useState<number>(0);
  const [isCameraTooClose, setIsCameraTooClose] = useState<boolean>(false);

  useEffect(() => {
    if (!currentFocusedId && lastTweens.length === 0 && cameraDistance > 0) {
      setCameraPreset({ ...cameraPreset, maxDistance: cameraDistance, minDistance: cameraDistance })
      if (!isCameraTooClose && cameraDistance < 0.5) {
        setIsCameraTooClose(true)
      }
      if (isCameraTooClose && cameraDistance >= 0.5) {
        setIsCameraTooClose(false)
      }
    }
  }, [cameraDistance])

  const { isStarted } = useContext(LoadingContext);

  useEffect(() => {
    if (isStarted) {
      setCameraPreset({ ...defaultCameraPreset })
    }
  }, [isStarted])

  return (
    <ControlsContext.Provider value={{ focus: focus, currentFocusedId: currentFocusedId, cancelPressed: cancelPressed }}>
      <PerspectiveCamera ref={camera} position={[0, 8, 20]} makeDefault></PerspectiveCamera>
      <OrbitControls ref={controls} {...cameraPreset} enableRotate={currentFocusedId === false} enableZoom={false} target={currentFocusedId === false && lastTweens.length === 0 ? characterTarget : focusTarget} enablePan={false}
        onChange={(event) => { setOrbitControlsChanged(Date.now()) }}
        mouseButtons={{
          LEFT: MOUSE.PAN,
          MIDDLE: MOUSE.DOLLY,
          RIGHT: MOUSE.ROTATE
        }} />
      <ambientLight intensity={0.32}></ambientLight>
      <spotLight ref={mainLight} castShadow position={[0, 10, 0]} rotation={[degToRad(-90), 0, 0]} intensity={1.8} distance={15}></spotLight>

      <CharacterController position={[0, 0, 2]} hidden={currentFocusedId !== false || isCameraTooClose} directionInput={lastTweens.length === 0 && isStarted ? rawDirectionInput : zeroVector2} camera={currentFocusedId === false ? camera.current : null!} setCharacterTarget={setCharacterTarget} destination={destination} orbitControlsChanged={orbitControlsChanged} setCameraDistance={setCameraDistance}></CharacterController>
      <DestinationConfirmation destination={destination}></DestinationConfirmation>

      <RoomPrefab scale={[scale, scale, scale]}></RoomPrefab>

      <mesh rotation={[degToRad(-90), 0, 0]} visible={false} name="floor-navigation" onClick={(ev) => { setDestination(ev.point) }}>
        <planeGeometry args={[roomWidth * scale, roomLength * scale]}></planeGeometry>
      </mesh>

      <mesh position={[0, 1.5, 0]} rotation={[degToRad(-90), 0, 0]} layers={Layer.CameraCollision} name="camera-collisions">
        <boxGeometry args={[roomWidth * 0.95 * scale, roomLength * 0.95 * scale, 3 * scale]}></boxGeometry>
        <meshBasicMaterial side={DoubleSide}></meshBasicMaterial>
      </mesh>

      <mesh position={[0, 1.5, 0]} rotation={[degToRad(-90), 0, 0]} layers={Layer.Collision} name="room-collisions">
        <boxGeometry args={[roomWidth * 0.95 * scale, roomLength * 0.95 * scale, 3 * scale]}></boxGeometry>
        <meshBasicMaterial side={DoubleSide}></meshBasicMaterial>
      </mesh>

      <mesh position={[0, 0.4, 0]} layers={Layer.CameraCollision}>
        <boxGeometry args={[2.4, 0.8, 2.4]}></boxGeometry>
        <meshBasicMaterial></meshBasicMaterial>
      </mesh>

      <mesh layers={Layer.Collision}>
        <boxGeometry args={[2.4, 1.2, 2.4]}></boxGeometry>
        <meshBasicMaterial></meshBasicMaterial>
      </mesh>

      <mesh layers={Layer.Collision}>
        <boxGeometry args={[0.8, 2, 0.8]}></boxGeometry>
        <meshBasicMaterial></meshBasicMaterial>
      </mesh>

      <mesh position={[0, 0.5, 4.5]} layers={Layer.Collision}>
        <boxGeometry args={[0.8, 1, 0.8]}></boxGeometry>
        <meshBasicMaterial></meshBasicMaterial>
      </mesh>

      <ProjectBoard position={[0, 1.35, -5]}></ProjectBoard>
      <Tree position={[0, 2, 0]}></Tree>
      <Instagram position={[-3.8, 1.35, 0]} rotation={[0, degToRad(90), 0]}></Instagram>
      <AiGenerated position={[-3.8, 1.35, -1.38]} rotation={[0, degToRad(90), 0]}></AiGenerated>
      <DrawingBoard position={[-3.8, 1.35, 1.38]} rotation={[0, degToRad(90), 0]}></DrawingBoard>
      <Timeline position={[3.797, 1.35, -1.92]} rotation={[0, degToRad(-90), 0]}></Timeline>
      <Skills position={[3.795, 1.35, 0]} rotation={[0, degToRad(-90), 0]}></Skills>
      <Projects position={[3.797, 1.35, 1.92]} rotation={[0, degToRad(-90), 0]}></Projects>
      <ThreeCorner position={[0, 1.5, 4.45]} rotation={[0, degToRad(180), 0]}></ThreeCorner>

    </ControlsContext.Provider>
  )
}