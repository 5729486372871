import { Color, Vector2, Vector3 } from 'three';

export const zeroVector2 = new Vector2(0, 0);
export const zeroVector3 = new Vector3(0, 0, 0);
export const negativeInfVector3 = new Vector3(0, -Infinity, 0);

export const colorWhite = new Color('#ffffff');

export enum Layer {
  Visible = 0,
  CameraCollision = 15,
  Collision = 30,
};

export enum Font {
  LatoLight = './fonts/lato-light.woff',
  LatoRegular = './fonts/lato-regular.woff',
  LatoBold = './fonts/lato-bold.woff',
  LibreItalic = './fonts/libre-italic.woff',
  LibreRegular = './fonts/libre-regular.woff',
  LibreBold = './fonts/libre-bold.woff',
}

export const DefaultDrawing = [
  {
    "tool": "pencil",
    "points": [
      180.9160284209347,
      292.5820965501047,
      180.9161284209347,
      292.5821965501047,
      180.9160284209347,
      294.30539175012336,
      180.9160284209347,
      296.02868695014183,
      180.9160284209347,
      297.75198215016025,
      180.9160284209347,
      299.47527735017866,
      180.9160284209347,
      301.19857255019707,
      182.69340113107,
      301.19857255019707,
      182.69340113107,
      302.92186775021554,
      182.69340113107,
      304.64516295023395,
      182.69340113107,
      306.36845815025237,
      182.69340113107,
      308.0917533502708,
      184.47077384120533,
      309.81504855028925,
      184.47077384120533,
      311.53834375030766,
      184.47077384120533,
      313.2616389503263,
      184.47077384120533,
      314.9849341503445,
      186.24814655134062,
      316.7082293503621,
      186.24814655134062,
      318.4315245503805,
      186.24814655134062,
      320.1548197504,
      186.24814655134062,
      321.87811495041734,
      188.02551926147592,
      323.6014101504358,
      188.02551926147623,
      325.32470535045445,
      188.02551926147592,
      327.04800055047286,
      189.80289197161153,
      328.77129575049133,
      189.80289197161153,
      330.49459095050975,
      189.80289197161153,
      332.21788615052816,
      189.80289197161153,
      333.9411813505466,
      191.58026468174683,
      333.9411813505466,
      191.58026468174683,
      337.38777175058345,
      191.58026468174683,
      339.11106695060187,
      193.35763739188215,
      342.557657350639,
      193.35763739188215,
      344.2809525506574,
      193.35763739188215,
      346.00424775067586,
      195.13501010201745,
      346.00424775067586,
      195.13501010201745,
      347.72754295069427,
      195.13501010201745,
      351.1741333507311,
      195.13501010201745,
      352.8974285507495,
      196.91238281215274,
      354.620723750768,
      196.91238281215274,
      356.3440189507864,
      196.91238281215274,
      358.0673141508048,
      198.6897555222881,
      359.79060935082356,
      198.6897555222881,
      361.51390455084197,
      198.6897555222881,
      364.9604949508788,
      200.4671282324234,
      366.68379015089727,
      200.4671282324234,
      370.13038055093403,
      200.4671282324234,
      371.8536757509525,
      202.24450094255872,
      373.5769709509709,
      202.24450094255872,
      377.02356135100774,
      202.24450094255872,
      378.7468565510265,
      204.021873652694,
      380.4701517510449,
      204.021873652694,
      382.1934469510633,
      204.021873652694,
      383.9167421510818,
      204.021873652694,
      385.6400373511002,
      205.7992463628293,
      389.08662775113703,
      205.7992463628293,
      390.80992295115544,
      205.79924636282962,
      392.53321815117306,
      205.7992463628293,
      394.2565133511915,
      205.7992463628293,
      395.9798085512099,
      207.57661907296492,
      397.7031037512283,
      207.57661907296492,
      399.4263989512467,
      207.57661907296492,
      401.1496941512651,
      207.57661907296492,
      402.8729893512839,
      207.57661907296492,
      404.5962845513023,
      209.3539917831002,
      406.3195797513207,
      209.3539917831002,
      408.0428749513391,
      209.3539917831002,
      409.7661701513576,
      209.3539917831002,
      411.489465351376,
      211.13136449323554,
      413.2127605513944,
      211.13136449323554,
      416.6593509514313,
      211.13136449323554,
      418.3826461514497,
      211.13136449323554,
      420.10594135146835,
      212.90873720337083,
      420.1059413514681,
      212.90873720337083,
      421.8292365514868,
      212.90873720337083,
      423.5525317515052,
      212.90873720337083,
      425.27582695152364,
      212.90873720337083,
      426.99912215154205,
      214.68610991350613,
      428.7224173515605,
      214.68610991350613,
      430.44571255157894,
      214.68610991350613,
      432.16900775159735,
      214.68610991350613,
      433.89230295161576,
      216.46348262364057,
      435.61559815163423,
      216.46348262364057,
      437.3388933516529,
      216.46348262364057,
      439.06218855167106,
      216.46348262364057,
      440.78548375168975,
      216.46348262364057,
      442.50877895170817,
      216.46348262364057,
      444.2320741517266,
      216.46348262364057,
      445.955369351745,
      216.46348262364057,
      447.67866455176346,
      218.2408553337759,
      449.4019597517819,
      218.2408553337759,
      451.12525495180034,
      218.2408553337759,
      452.84855015181876,
      218.2408553337759,
      454.5718453518372,
      218.2408553337759,
      456.2951405518558,
      218.2408553337759,
      458.0184357518743,
      218.2408553337759,
      459.7417309518927,
      218.2408553337759,
      461.4650261519111,
      220.0182280439112,
      463.1883213519295,
      220.0182280439112,
      464.911616551948,
      220.0182280439112,
      466.6349117519664,
      220.0182280439112,
      468.3582069519848,
      220.0182280439112,
      470.08150215200243,
      220.0182280439112,
      471.80479735202084,
      220.0182280439112,
      473.5280925520393
    ],
    "stroke": "#000000",
    "strokeWidth": 5
  },
  {
    "tool": "pencil",
    "points": [
      323.1058452317596,
      258.11619254973596,
      323.10594523175956,
      258.11629254973593,
      323.1058452317596,
      259.83948774975437,
      324.8832179418949,
      259.83948774975437,
      324.8832179418949,
      261.5627829497725,
      324.8832179418949,
      263.2860781497912,
      326.66059065203024,
      263.2860781497912,
      326.66059065203024,
      265.0093733498096,
      326.66059065203024,
      266.7326685498281,
      328.4379633621655,
      268.4559637498465,
      328.4379633621655,
      270.17925894986485,
      330.21533607230083,
      271.9025541498833,
      330.21533607230083,
      273.6258493499017,
      330.21533607230083,
      275.3491445499204,
      330.21533607230083,
      277.0724397499389,
      331.9927087824361,
      278.7957349499573,
      331.9927087824361,
      280.5190301499757,
      331.9927087824361,
      282.24232534999413,
      331.9927087824361,
      283.9656205500126,
      333.7700814925714,
      285.688915750031,
      333.7700814925714,
      287.4122109500494,
      333.7700814925714,
      290.8588013500863,
      335.54745420270706,
      292.5820965501047,
      335.54745420270706,
      294.30539175012314,
      335.54745420270706,
      296.02868695014183,
      337.3248269128423,
      297.75198215016025,
      337.3248269128423,
      299.47527735017866,
      337.3248269128423,
      302.92186775021554,
      337.3248269128423,
      304.64516295023395,
      337.3248269128423,
      306.36845815025237,
      337.3248269128423,
      308.0917533502708,
      339.10219962297765,
      309.81504855028925,
      339.10219962297765,
      311.53834375030766,
      339.10219962297765,
      313.2616389503261,
      339.10219962297765,
      314.9849341503445,
      339.10219962297765,
      316.7082293503621,
      339.10219962297765,
      318.4315245503805,
      340.87957233311295,
      320.1548197504,
      340.87957233311295,
      321.8781149504176,
      340.87957233311295,
      323.60141015043604,
      340.87957233311295,
      325.32470535045445,
      340.87957233311295,
      327.04800055047286,
      340.87957233311295,
      328.77129575049133,
      342.65694504324824,
      330.49459095050975,
      342.65694504324824,
      332.21788615052816,
      342.65694504324824,
      333.9411813505466,
      342.65694504324824,
      335.66447655056504,
      342.65694504324824,
      337.38777175058345,
      342.65694504324824,
      339.11106695060215,
      344.43431775338354,
      340.8343621506206,
      344.43431775338354,
      342.557657350639,
      344.43431775338354,
      344.2809525506574,
      344.43431775338354,
      346.00424775067586,
      344.43431775338354,
      347.72754295069427,
      344.43431775338354,
      349.4508381507127,
      346.2116904635189,
      351.1741333507311,
      346.2116904635189,
      354.620723750768,
      346.2116904635189,
      356.3440189507867,
      346.2116904635189,
      358.0673141508051,
      346.2116904635189,
      359.79060935082356,
      346.2116904635189,
      361.51390455084197,
      347.98906317365413,
      363.2371997508604,
      347.98906317365413,
      364.9604949508788,
      347.98906317365413,
      366.68379015089727,
      347.98906317365413,
      368.4070853509157,
      347.98906317365413,
      370.13038055093403,
      347.98906317365413,
      371.8536757509525,
      347.98906317365413,
      373.5769709509709,
      349.7664358837895,
      377.0235613510081,
      349.7664358837895,
      378.7468565510265,
      349.7664358837895,
      380.4701517510449,
      349.7664358837895,
      382.1934469510633,
      349.7664358837895,
      383.9167421510818,
      349.7664358837895,
      385.6400373511002,
      349.7664358837895,
      387.36333255111776,
      351.5438085939248,
      387.36333255111776,
      351.5438085939248,
      389.08662775113703,
      351.5438085939248,
      390.80992295115544,
      351.5438085939248,
      392.53321815117306,
      351.5438085939248,
      395.9798085512099,
      353.3211813040601,
      397.7031037512283,
      353.3211813040601,
      399.4263989512467,
      353.32118130406036,
      401.1496941512651,
      353.3211813040601,
      402.8729893512839,
      353.3211813040601,
      404.5962845513023,
      355.0985540141957,
      404.5962845513023,
      355.0985540141957,
      406.3195797513207,
      355.0985540141957,
      408.0428749513391,
      355.0985540141957,
      409.7661701513576,
      356.87592672433095,
      411.489465351376,
      356.87592672433095,
      413.2127605513944,
      356.87592672433095,
      414.9360557514128,
      356.87592672433095,
      416.6593509514313,
      358.6532994344663,
      418.3826461514497,
      358.6532994344663,
      420.10594135146835,
      358.6532994344663,
      421.8292365514868,
      360.4306721446016,
      421.8292365514868,
      360.4306721446016,
      423.5525317515052,
      360.4306721446016,
      425.27582695152364,
      360.4306721446016,
      426.99912215154205,
      362.2080448547369,
      428.7224173515605
    ],
    "stroke": "#000000",
    "strokeWidth": 5
  },
  {
    "tool": "pencil",
    "points": [
      138.25908337768675,
      533.843424552685,
      138.25918337768675,
      533.8435245526849,
      138.25908337768675,
      535.5667197527033,
      140.03645608782207,
      535.5667197527033,
      140.03645608782207,
      537.2900149527221,
      141.81382879795737,
      537.2900149527221,
      141.81382879795737,
      539.0133101527405,
      143.59120150809267,
      540.7366053527589,
      143.59120150809267,
      542.4599005527763,
      143.59120150809267,
      544.1831957527958,
      145.368574218228,
      544.1831957527958,
      145.368574218228,
      545.9064909528134,
      145.368574218228,
      547.6297861528318,
      147.1459469283633,
      547.6297861528318,
      147.1459469283633,
      549.3530813528503,
      147.1459469283633,
      551.0763765528687,
      148.92331963849858,
      551.0763765528687,
      148.92331963849858,
      552.7996717528871,
      148.92331963849858,
      554.5229669529056,
      150.70069234863422,
      554.5229669529056,
      150.7006923486339,
      556.2462621529239,
      150.70069234863422,
      557.9695573529424,
      152.47806505876923,
      557.9695573529424,
      152.47806505876952,
      559.6928525529609,
      154.2554377689048,
      561.4161477529791,
      154.2554377689048,
      563.1394429529976,
      156.03281047904014,
      563.1394429529976,
      156.03281047904014,
      564.8627381530163,
      156.03281047904014,
      566.5860333530347,
      157.81018318917543,
      566.5860333530347,
      157.81018318917543,
      568.3093285530532,
      159.58755589931073,
      570.0326237530716,
      161.36492860944605,
      571.75591895309,
      161.36492860944605,
      573.4792141531084,
      163.14230131958135,
      573.4792141531084,
      163.14230131958135,
      575.2025093531269,
      164.91967402971665,
      575.2025093531269,
      164.91967402971665,
      576.9258045531453,
      164.91967402971665,
      578.6490997531637,
      166.69704673985197,
      578.6490997531637,
      168.47441944998727,
      578.6490997531637,
      168.47441944998727,
      580.3723949531822,
      170.25179216012287,
      580.3723949531822,
      170.25179216012287,
      582.0956901532008,
      172.02916487025817,
      582.0956901532008,
      172.02916487025817,
      583.8189853532193,
      173.8065375803935,
      583.8189853532193,
      173.8065375803935,
      585.5422805532377,
      175.5839102905288,
      585.5422805532377,
      177.3612830006641,
      585.5422805532377,
      177.3612830006641,
      587.2655757532561,
      179.1386557107994,
      587.2655757532561,
      179.1386557107994,
      588.9888709532746,
      180.9160284209347,
      588.9888709532746,
      182.69340113107,
      588.9888709532746,
      182.69340113107,
      590.712166153293,
      184.47077384120533,
      590.712166153293,
      184.47077384120533,
      592.4354613533114,
      186.24814655134062,
      592.4354613533114,
      188.02551926147592,
      592.4354613533114,
      188.02551926147592,
      594.1587565533298,
      189.80289197161153,
      594.1587565533298,
      189.80289197161153,
      595.8820517533483,
      191.58026468174683,
      595.8820517533483,
      193.35763739188215,
      595.8820517533483,
      193.35763739188215,
      597.6053469533667,
      195.13501010201745,
      597.6053469533667,
      196.91238281215274,
      597.6053469533667,
      196.91238281215274,
      599.3286421533854,
      198.6897555222881,
      599.3286421533854,
      200.4671282324234,
      601.0519373534039,
      202.24450094255872,
      601.0519373534039,
      204.021873652694,
      601.0519373534039,
      204.021873652694,
      602.7752325534223,
      205.79924636282962,
      602.7752325534223,
      207.57661907296492,
      602.7752325534223,
      209.3539917831002,
      604.4985277534406,
      211.13136449323554,
      604.4985277534406,
      212.90873720337083,
      604.4985277534406,
      212.90873720337083,
      606.2218229534591,
      214.68610991350613,
      606.2218229534591,
      216.46348262364057,
      606.2218229534591,
      218.2408553337759,
      606.2218229534591,
      220.0182280439112,
      606.2218229534591,
      220.0182280439112,
      607.9451181534774,
      221.7956007540465,
      607.9451181534774,
      223.5729734641818,
      607.9451181534774,
      225.3503461743171,
      607.9451181534774,
      225.3503461743171,
      609.6684133534959,
      227.1277188844524,
      609.6684133534959,
      228.90509159458773,
      609.6684133534959,
      230.68246430472303,
      609.6684133534959,
      232.45983701485832,
      609.6684133534959,
      234.23720972499393,
      609.6684133534959,
      234.23720972499393,
      611.3917085535143,
      236.01458243512923,
      611.3917085535143,
      237.79195514526455,
      611.3917085535143,
      239.56932785539985,
      611.3917085535143,
      241.34670056553514,
      611.3917085535143,
      241.34670056553514,
      613.1150037535327,
      243.12407327567047,
      613.1150037535327,
      244.90144598580576,
      613.1150037535327,
      246.67881869594106,
      613.1150037535327,
      248.45619140607639,
      613.1150037535327,
      250.23356411621168,
      613.1150037535327,
      252.01093682634698,
      613.1150037535327,
      253.7883095364826,
      613.1150037535327,
      253.7883095364826,
      614.8382989535504,
      255.5656822466179,
      614.8382989535504,
      257.3430549567532,
      614.8382989535504,
      259.12042766688853,
      614.8382989535504,
      260.8978003770238,
      614.8382989535504,
      262.6751730871591,
      614.8382989535504,
      264.4525457972945,
      614.8382989535504,
      266.2299185074298,
      614.8382989535504,
      268.00729121756507,
      614.8382989535504,
      269.78466392770036,
      614.8382989535504,
      271.56203663783594,
      616.5615941535696,
      273.3394093479713,
      616.5615941535696,
      275.1167820581066,
      616.5615941535696,
      276.8941547682419,
      616.5615941535696,
      278.6715274783772,
      616.5615941535696,
      280.4489001885125,
      616.5615941535696,
      282.22627289864784,
      616.5615941535696,
      284.00364560878313,
      616.5615941535696,
      285.7810183189184,
      616.5615941535696,
      287.5583910290537,
      616.5615941535696,
      289.33576373918936,
      616.5615941535696,
      291.11313644932466,
      616.5615941535696,
      292.89050915945995,
      616.5615941535696,
      294.6678818695941,
      616.5615941535696,
      296.4452545797294,
      616.5615941535696,
      298.222627289865,
      616.5615941535696,
      300.0000000000003,
      616.5615941535696,
      301.7773727101356,
      616.5615941535696,
      303.5547454202709,
      616.5615941535696,
      305.33211813040623,
      616.5615941535696,
      307.10949084054147,
      616.5615941535696,
      308.8868635506768,
      616.5615941535696,
      310.6642362608122,
      616.5615941535696,
      312.4416089709474,
      616.5615941535696,
      314.21898168108277,
      616.5615941535696,
      315.99635439121806,
      616.5615941535696,
      317.77372710135364,
      616.5615941535696,
      319.551099811489,
      616.5615941535696,
      321.3284725216243,
      616.5615941535696,
      323.1058452317596,
      616.5615941535696,
      324.8832179418949,
      616.5615941535696,
      326.66059065203024,
      616.5615941535696,
      328.4379633621655,
      616.5615941535696,
      328.4379633621655,
      614.8382989535504,
      330.21533607230083,
      614.8382989535504,
      331.9927087824361,
      614.8382989535504,
      333.7700814925714,
      614.8382989535504,
      335.54745420270706,
      614.8382989535504,
      335.54745420270706,
      613.1150037535327,
      337.3248269128423,
      613.1150037535327,
      339.10219962297765,
      613.1150037535327,
      340.87957233311295,
      613.1150037535327,
      340.87957233311295,
      611.3917085535143,
      342.65694504324824,
      611.3917085535143,
      344.43431775338354,
      609.6684133534959,
      346.2116904635189,
      609.6684133534959,
      347.98906317365413,
      607.9451181534774,
      349.7664358837895,
      607.9451181534774,
      351.5438085939248,
      606.2218229534591,
      353.3211813040601,
      604.4985277534406,
      355.0985540141957,
      604.4985277534406,
      355.0985540141957,
      602.7752325534223,
      356.87592672433095,
      602.7752325534223,
      358.6532994344663,
      601.0519373534039,
      358.6532994344663,
      599.3286421533854,
      360.4306721446016,
      599.3286421533854,
      362.2080448547369,
      597.6053469533667,
      363.98541756487225,
      597.6053469533667,
      363.98541756487225,
      595.8820517533483,
      365.76279027500755,
      595.8820517533483,
      365.76279027500755,
      594.1587565533298,
      367.54016298514284,
      594.1587565533298,
      369.31753569527814,
      592.4354613533114,
      371.0949084054135,
      592.4354613533114,
      371.0949084054135,
      590.712166153293,
      372.8722811155491,
      590.712166153293,
      374.6496538256832,
      588.9888709532746,
      376.4270265358185,
      587.2655757532561,
      378.2043992459538,
      587.2655757532561,
      378.2043992459538,
      585.5422805532377,
      379.9817719560891,
      583.8189853532193,
      383.53651737636005,
      583.8189853532193,
      383.53651737636005,
      582.0956901532008,
      385.31389008649535,
      582.0956901532008,
      387.09126279663064,
      580.3723949531822,
      388.86863550676594,
      578.6490997531637,
      390.6460082169013,
      576.9258045531453,
      392.42338092703653,
      576.9258045531453,
      394.2007536371719,
      575.2025093531269,
      395.9781263473072,
      573.4792141531084,
      397.7554990574425,
      573.4792141531084,
      397.7554990574425,
      571.75591895309,
      399.5328717675778,
      571.75591895309,
      399.5328717675778,
      570.0326237530716,
      401.31024447771335,
      570.0326237530716,
      401.31024447771335,
      568.3093285530532,
      403.0876171878487,
      568.3093285530532,
      403.0876171878487,
      566.5860333530347,
      404.864989897984,
      564.8627381530163,
      406.6423626081193,
      564.8627381530163,
      408.4197353182546,
      563.1394429529976,
      410.19710802838995,
      563.1394429529976,
      410.19710802838995,
      561.4161477529791,
      411.9744807385252,
      561.4161477529791,
      411.9744807385252,
      559.6928525529609,
      413.75185344866054,
      559.6928525529609,
      413.75185344866054,
      557.9695573529424,
      415.52922615879584,
      556.2462621529239,
      417.30659886893113,
      554.5229669529056,
      419.08397157906677,
      554.5229669529056,
      419.08397157906643,
      552.7996717528871,
      420.861344289202,
      552.7996717528871,
      420.861344289202,
      551.0763765528687,
      422.63871699933736,
      551.0763765528687,
      422.63871699933736,
      549.3530813528503,
      424.41608970947266,
      547.6297861528318,
      426.19346241960795,
      547.6297861528318,
      426.19346241960795,
      545.9064909528134,
      426.19346241960795,
      544.1831957527958,
      427.97083512974325,
      544.1831957527958,
      427.97083512974325,
      542.4599005527763,
      429.7482078398786,
      542.4599005527763,
      429.7482078398786,
      540.7366053527589,
      431.52558055001396,
      540.7366053527589,
      431.52558055001396,
      539.0133101527405,
      431.52558055001396,
      537.2900149527221,
      433.3029532601492,
      537.2900149527221,
      433.3029532601492,
      535.5667197527033,
      435.08032597028455,
      533.843424552685,
      436.85769868042007,
      532.1201293526666,
      436.85769868042007,
      530.3968341526481,
      438.6350713905554,
      530.3968341526481,
      438.6350713905554,
      528.6735389526298,
      438.6350713905554,
      526.9502437526113,
      440.4124441006908,
      525.2269485525928,
      440.4124441006908,
      523.5036533525745,
      442.189816810826,
      523.5036533525745,
      442.189816810826,
      521.780358152556,
      442.189816810826,
      520.0570629525376,
      443.96718952096137,
      520.0570629525376,
      443.96718952096137,
      518.3337677525188,
      443.96718952096137,
      516.6104725525005,
      445.74456223109667,
      516.6104725525005,
      447.52193494123196,
      513.1638821524637,
      447.52193494123196,
      511.4405869524452,
      449.29930765136726,
      509.7172917524268,
      451.0766803615026,
      507.9939965524083,
      451.0766803615026,
      506.2707013523899,
      452.854053071637,
      504.5474061523715,
      452.854053071637,
      502.82411095235307,
      454.63142578177235,
      501.1008157523346,
      456.4087984919076,
      499.3775205523159,
      456.4087984919076,
      497.6542253522975,
      458.18617120204294,
      495.930930152279,
      458.18617120204294,
      494.2076349522606,
      459.96354391217824,
      492.4843397522422,
      459.96354391217824,
      490.7610445522238,
      461.74091662231353,
      490.7610445522238,
      461.74091662231353,
      489.0377493522053,
      463.51828933244883,
      487.3144541521869,
      465.2956620425844,
      482.14456855213143,
      467.07303475271976,
      480.421273352113,
      467.07303475271976,
      478.69797815209455,
      468.85040746285506,
      476.97468295207614,
      468.85040746285506,
      475.2513877520577,
      470.62778017299036,
      475.2513877520577,
      470.62778017299036,
      473.5280925520393,
      472.40515288312565,
      471.80479735202084,
      472.40515288312565,
      470.08150215200243,
      474.182525593261,
      470.08150215200243,
      474.182525593261,
      468.3582069519848,
      474.182525593261,
      466.6349117519664,
      475.95989830339624,
      464.911616551948,
      475.95989830339624,
      463.1883213519295,
      477.7372710135316,
      461.4650261519111,
      479.5146437236669,
      459.7417309518927,
      479.5146437236669,
      458.0184357518743,
      479.5146437236669,
      456.2951405518558,
      481.2920164338025,
      454.5718453518372,
      481.2920164338022,
      452.84855015181876,
      481.2920164338025,
      451.12525495180034,
      483.0693891439378,
      451.12525495180034,
      483.0693891439375,
      449.4019597517819,
      483.0693891439375,
      447.67866455176346,
      483.0693891439375,
      445.955369351745,
      483.0693891439378,
      444.2320741517266,
      483.0693891439378,
      442.50877895170817,
      483.0693891439375,
      440.78548375168975,
      483.0693891439375,
      439.06218855167134,
      483.0693891439378,
      437.3388933516529
    ],
    "stroke": "#000000",
    "strokeWidth": 5
  }
]