import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Layer, Line, Stage } from 'react-konva';
import { DefaultDrawing } from '../utils/Constant';

export interface CanvasLine {
  tool: string,
  points: number[],
  stroke: string,
  strokeWidth: number,
}

export function SketchCanvas() {
  const [canvasLines, setCanvasLines] = useState<CanvasLine[]>(DefaultDrawing);
  const stage = useRef<Konva.Stage>(null!);
  const isDrawing = useRef(false);

  const currentColor = '#000000'

  useEffect(() => {
    if (isMobile) {
      setCanvasLines(
        DefaultDrawing.map(line => {
          const temp = line.points.map(point => point / 3);
          line.points = temp;
          return line;
        })
      )
    }
  }, [])

  const handleMouseDown = (event: KonvaEventObject<MouseEvent>) => {
    isDrawing.current = true;
    if (!event.target) return;
    const stage = event.target.getStage();
    if (!!stage) {
      const pos = stage.getPointerPosition();
      if (!!pos) {
        setCanvasLines([...canvasLines, { tool: 'pencil', points: [pos.x, pos.y, pos.x + 0.0001, pos.y + 0.0001], stroke: currentColor, strokeWidth: 5 }]);
      }
    }
  };

  const handleMouseMove = (event: KonvaEventObject<MouseEvent>) => {
    // no drawing - skipping
    if (!isDrawing.current) {
      return;
    }
    const stage = event.target.getStage();
    if (!!stage) {
      const point = stage.getPointerPosition();
      if (!!point) {
        let lastLine = canvasLines[canvasLines.length - 1] as CanvasLine;
        // add point
        lastLine.points = lastLine.points.concat([point.x, point.y]);

        // replace last
        canvasLines.splice(canvasLines.length - 1, 1, lastLine);
        setCanvasLines(canvasLines.concat());
      }
    }
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  function _exportImage() {
    const srcCanvas = document.querySelector('.konvajs-content')?.children[0] as HTMLCanvasElement;
    if (!srcCanvas) return;

    const destinationCanvas = document.createElement('canvas') as HTMLCanvasElement;
    destinationCanvas.width = srcCanvas.width;
    destinationCanvas.height = srcCanvas.height;

    const destCtx = destinationCanvas.getContext('2d');

    if (!destCtx) return;
    destCtx.fillStyle = '#ffffff';
    destCtx.fillRect(0, 0, srcCanvas.width, srcCanvas.height);
    destCtx.drawImage(srcCanvas, 0, 0);

    return destinationCanvas.toDataURL();
  }

  function cv_clearCanvas() {
    setCanvasLines([]);
  }

  function cv_download() {
    const img = _exportImage();

    if (!!img) {
      var a = document.createElement('a');
      a.href = img;
      a.download = `drawing-board-iv-vr-${Date.now()}`;
      a.click();
      a.remove()
    }
  }

  useEffect(() => {
    window.addEventListener('cv_clearCanvas', cv_clearCanvas)
    window.addEventListener('cv_download', cv_download)

    return () => {
      window.removeEventListener('cv_clearCanvas', cv_clearCanvas)
      window.removeEventListener('cv_download', cv_download)
    }
  }, [])

  return (
    <Stage
      ref={stage}
      width={isMobile ? 200 : 600}
      height={isMobile ? 300 : 900}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      style={{ display: "none" }}
    >
      <Layer>
        {canvasLines.map((line, i) => (
          <Line
            key={i}
            points={line.points}
            stroke={line.stroke}
            strokeWidth={line.strokeWidth}
            tension={0.5}
            lineCap="round"
            lineJoin="round"
            globalCompositeOperation={
              line.tool === 'eraser' ? 'destination-out' : 'source-over'
            }
          />
        ))}
      </Layer>
    </Stage>
  )
}